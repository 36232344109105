import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, message } from 'antd';
import { AuthLayout } from '../../layouts/auth/authLayout';
import { userLogin } from '../../appRedux/actions/Authentication';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';
import { countryCodeList } from '../../helpers/sample-response';
import { userSyncData } from '../../appRedux/actions/DataSync';
import './login.less';
import { Plugins, Capacitor } from "@capacitor/core";
import {
    InfoCircleOutlined
} from '@ant-design/icons';

const { Option } = Select;
const sortedCountryCodeList = sortBy(countryCodeList, ['code']);

const LoginPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { isUserLoggedIn } = useSelector(({ auth }) => auth);
    const { isLoginToOTP, otpdata } = useSelector(({ auth }) => auth);
    const [showPrefixSelector, setShowPrefixSelector] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (Capacitor.isNative) {
            Plugins.App.addListener("backButton", (e) => {
            const pathList = ['/login', '/home']
            if (pathList.includes(window.location.pathname)){
                // Show A Confirm Box For User to exit app or not
                let ans = window.confirm("Are you sure you want to exit?");
                if (ans) {
                    Plugins.App.exitApp();
                } 
            }else{
                history.goBack()
            }
            });
        }
        if (user && user.key) {
            // call back sync api
            if(user.is_admin){
                history.push('/admin-panel')
            }else{
                dispatch(userSyncData())
                history.push('/home')
            }
        }
    }, [])

    useEffect(() => {
        if (isUserLoggedIn) {
            JSON.parse(localStorage.getItem('user')).is_admin ? history.push('/admin-panel')
            :history.push('/home')
        }
    }, [isUserLoggedIn])

    useEffect(() => {
        if (isLoginToOTP) {
            history.push(
                {pathname:'/otp',
                state: {'mobile_number': otpdata.mobile_number, 'name': otpdata.name,
                'prefix':otpdata.mobile_number.slice(0,3),
                'mobwoprefix': otpdata.mobile_number.slice(3)}
            });        //should be verifyotp
        }
    }, [isLoginToOTP])

    const onFinish = values => {
        let data = {
            password: values.password,
        }
        if (values.nric.includes("@")) {
            data = { ...data, email: values.nric }
        }
        else{
            data = { ...data, nric: values.nric }
        }
        dispatch(userLogin(data))
    };

    const navigateToSignup = () => {
        history.push('/signup')
    }

    const prefixSelector = (
        <Form.Item
            rules={[
                { required: true, message: 'Please select country code' },
            ]}
            name='prefix'
            noStyle>
            <Select
                style={{ width: 100 }}
                showSearch
                optionFilterProp='children'
                filterOption={true}
            >
                {
                    sortedCountryCodeList.map(value => <Option key={value.code} value={value.code}>{value.code}</Option>)
                }
            </Select>
        </Form.Item>
    );

    const handleUserCredChange = (e) => {
        setShowPrefixSelector((!isNaN(e.target.value.charAt(0))) && e.target.value.length > 0)
        form.setFieldsValue({
            'email': e.target.value,
            'mobile_number': e.target.value
        })
    }

    const info = () => {
        message.info('NRIC/FIN/Passport number used to register at Osler Health International');
    };

    return (
        <AuthLayout>
            <div className='outer-container-accounts'>
                <div className='inner-container-accounts'>
                    <h1 className='auth-titles'>Login</h1>
                    <Form
                        name='login'
                        onFinish={onFinish}
                        form={form}
                    >

                        <Form.Item
                            name='nric'
                            rules={[
                                { required: true, message: 'Please enter NRIC/FIN/Passport number' },
                                { min: 6, message: 'Please enter valid NRIC/FIN/ Passport number' },
                                { max: 50, message: 'Please enter valid NRIC/FIN/ Passport number' }
                            ]}
                        >
                            <Input
                                placeholder='NRIC/FIN/Passport number'
                                style={{ width: '100%' }}
                                suffix={
                                    <InfoCircleOutlined
                                        onClick={info}
                                    /> 
                                }
                            />
                        </Form.Item>


                        <Form.Item
                            rules={[{ required: true, message: 'Please enter Password' }]}
                            name='password'>
                            <Input.Password
                                visibilityToggle={true}
                                placeholder='Password' />
                        </Form.Item>
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                Login
                            </Button>
                        </Form.Item>
                        <div className='auth-nav-links width-100'>
                            <div className='width-50 hover-links-wrapper' onClick={() => history.push('/signup')}>
                                <p>Don't have account?</p>
                                    <span className='btn-link'>Sign up!</span>
                            </div>
                            <div className='width-50 text-right-align'>
                                <p className='btn-link'>
                                    <span onClick={() => history.push('/forgotpass')} className='btn-link'>
                                        Forgot Password?
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    )

}

export default LoginPage;