import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination } from 'antd';
import {
    CloseCircleTwoTone, CheckCircleTwoTone
} from '@ant-design/icons';
import moment from 'moment';
// import './admin-table.less';
import '../../assets/styles/table.less';


const AdminTable = (props) => {

    const { responseData, totalCount, page, page_size } = props

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    
    useEffect(() => {

        if (responseData.length > 0){
            let cols = [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name'
                }, 
                {
                    title: 'IC',
                    dataIndex: 'nric',
                    key: 'nric'
                }, 
                {
                    title: 'Mobile Number',
                    dataIndex: 'mobile_number',
                    key: 'mobile_number'
                },
                {
                    title: 'Login Time',
                    dataIndex: 'login_time',
                    key: 'login_time'
                }, 
                {
                    title: 'Login Status',
                    dataIndex: 'is_active',
                    key: 'is_active'
                }
            ]
            let rows = []
            let temp_id = 0
            responseData.map(val => {
                rows.push({
                    key: ++temp_id,
                    name: val.name,
                    nric: val.nric,
                    mobile_number: val.mobile_number,
                    login_time: val.login_time ? moment(val.login_time).utcOffset('+0800').format('DD MMM YYYY HH:mm') :'-',
                    is_active: val.is_active ? <CheckCircleTwoTone twoToneColor='#52c41a' />: <CloseCircleTwoTone twoToneColor='#F92F04' />
                })
            })

            setColumns(cols)
            setRows(rows)
        }

    }, [responseData])
    

    const changePage = (page) => {
        props.changePage(page)
    }

    return (
            <>
            {responseData.length > 0 ? (
                
                <div className="table-layout-container">

                    <div className="table-layout-container-item-1">
                        <Table
                            className = "table-layout"
                            columns={columns}
                            dataSource={rows}
                            pagination={false}
                        />
                    </div>

                    <div className="table-layout-container-item-2">
                        <Pagination
                            className = "table-layout-pagination"
                            onChange={changePage}
                            total={totalCount}
                            current={page}
                            pageSize={page_size}
                        />
                    </div>

                </div>

            ) : (
                <> </>
            )}
        </>
    );
}

export default AdminTable;
