import { hideLoader, showLoader } from './Loader';
import { showMessage } from '../../helpers'
import { keyMeasurementsConstants } from '../constants';
import { getKeyMeasurementData } from '../../services';

export const userKeyMeasurement = (params, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        getKeyMeasurementData(params).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Unable to fetch data');
                    dispatch({ type: keyMeasurementsConstants.KEYMEASUREMENTS_FAILURE });
                } else {
                    dispatch({ type: keyMeasurementsConstants.KEYMEASUREMENTS_SUCCESS, response });
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: keyMeasurementsConstants.KEYMEASUREMENTS_FAILURE });
                showMessage('error', 'Unable to fetch data')
                console.log(error)
            }
        )
    }
}

export const keyMeasurementLoad = () => {
    return dispatch => {
        dispatch({ type: keyMeasurementsConstants.KEYMEASUREMENTS_LOADING })
    }
}