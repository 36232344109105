import { otpConstants } from '../constants';

const initialSettings = {
    isOTPVerified: false
};

const OTPVerification = (state = initialSettings, action) => {
    switch (action.type) {

        case otpConstants.OTP_REQUEST:
            return {
                ...state,
                isOTPVerified: false
            };
        case otpConstants.OTP_SUCCESS:
            return {
                ...state,
                isOTPVerified: true
            };
        case otpConstants.OTP_FAILURE:
            return {
                ...state,
                isOTPVerified: false
            };

        default:
            return state;
    }
};

export default OTPVerification;
