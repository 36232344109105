import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination } from 'antd';
import {
    ArrowDownOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './measurement-table.less';

import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';


const KeyMeasurementTable = (props) => {

    const dispatch = useDispatch();
    const { responseData, totalCount, page, page_size } = props

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    
    useEffect(() => {

        if (responseData.length > 0){
            let cols = [
                {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date'
                }, 
                {
                    title: 'Age',
                    dataIndex: 'age',
                    key: 'age'
                }, 
                {
                    title: 'Weight',
                    dataIndex: 'weight',
                    key: 'weight'
                },
                {
                    title: 'Height',
                    dataIndex: 'height',
                    key: 'height'
                }, 
                {
                    title: 'BP',
                    dataIndex: 'bp',
                    key: 'bp'
                },
                {
                    title: 'BMI',
                    dataIndex: 'bmi',
                    key: 'bmi'
                },
                {
                    title: 'Clothed',
                    dataIndex: 'clothed',
                    key: 'clothed'
                },
                {
                    title: 'Head Circumference',
                    dataIndex: 'headcirc',
                    key: 'headcirc'
                },
            ]
      
            let rows = []
            let temp_id = 0
            responseData.map(val => {
                rows.push({
                    key: ++temp_id,
                    date: moment(val.date).utcOffset('+0800').format('DD MMM YYYY'),
                    age: processAge(val.dob,val.date),
                    weight: val.weight ? Number(val.weight).toFixed(1) + ' Kg' : '-',
                    height: val.height ? Number(val.height).toFixed(1) + ' cm' : '-',
                    bp: val.bp ? val.bp + ' mmHg' : '-',
                    bmi: val.weight && val.height ? Number(Number(val.weight) / (Number(val.height) * Number(val.height) * 0.01 * 0.01)).toFixed(2) : '-',
                    clothed: moment().diff(val.dob, 'months') < 6 ? val.clothed ? 'Yes' : 'No' : '-',
                    headcirc: val.headcirc ? Number(val.headcirc).toFixed(2) : '-'
                })
            })

            setColumns(cols)
            setRows(rows)
        }

    }, [responseData])
    
    
    const processAge = (dob,date) => {
        if(dob){
            let key_measure_date = moment(date).utcOffset('+0800')
            let diffDuration = moment.duration(key_measure_date.diff(dob));
            let yrs = diffDuration.years()
            let mth = diffDuration.months()
            let dys = diffDuration.days()
            if(yrs > 0 && mth > 0){
                return yrs + 'y ' + mth + 'm'
            }else if(yrs === 0 && mth > 0){
                return mth + 'm'
            }else if(yrs === 0 && mth === 0 && dys > 0){
                return dys + ' days'
            }
            else if(yrs === 0 && mth === 0 && dys === 0){
                return '1 day'
            }
        }
        return '-'
    }

    const changePage = (page) => {
        props.changePage(page)
    }

    return (
        
        <>

            {responseData.length > 0 ? (
                
                <div className="table-layout-container">

                    <div className="table-layout-container-item-1">
                        <Table
                            className = "table-layout"
                            columns={columns}
                            dataSource={rows}
                            pagination={false}
                        />
                    </div>

                    <div className="table-layout-container-item-2">
                        <Pagination
                            className = "table-layout-pagination"
                            onChange={changePage}
                            total={totalCount}
                            current={page}
                            pageSize={page_size}
                        />
                    </div>

                </div>

            ) : (
                <> </>
            )}

        </>
        
    );
}

export default KeyMeasurementTable;
