import React, { useEffect } from 'react';
import { Button, Form, Input, DatePicker, Select } from 'antd';
import { AuthLayout } from '../../layouts/auth/authLayout';
import { resetPassword } from '../../appRedux/actions/resetpassword';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './resetpassword.less';



const ResetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isPasswordReset } = useSelector(({ resetpass }) => resetpass);

    useEffect(() => {
        if (isPasswordReset) {
            JSON.parse(localStorage.getItem('user')).is_admin ? history.push('/admin-panel')
            :history.push('/home');
            history.go(0);
        }
    }, [isPasswordReset]);

    const onFinish = (values) => {
        dispatch(resetPassword(values));
    };


    return (
        <AuthLayout
            isBackArrow={true}
        >

            <div className="outer-container-accounts">
                <div className="inner-container-accounts">
                    <h1 className='auth-titles'>Change Password</h1>
                    <Form
                        name='resetpassword'
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name='old_password'
                            rules={[
                                { required: true, message: 'Please enter your Old Password' },
                            ]}
                        >
                            <Input.Password visibilityToggle={true} placeholder='Old Password' />
                        </Form.Item>
                        <Form.Item
                            name='new_password1'
                            rules={[
                                { required: true, message: 'Please enter your New Password' },
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|\W))(?=.*[^\w\d]).{8,}$/,
                                    message: 'Password must be atleast 8 characters and must include atleast 1 uppercase, 1 lowercase, 1 number and 1 special character'
                                }
                            ]}
                        >
                            <Input.Password visibilityToggle={true} placeholder='New Password' />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                { required: true, message: 'Please enter Confirm New Password' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('new_password1') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                            name='new_password2'
                        >
                            <Input.Password
                                visibilityToggle={true}
                                placeholder='Confirm New Password'
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button className='reset-password-button' type='primary' htmlType='submit'>
                                Reset Password
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    );
};

export default ResetPassword;
