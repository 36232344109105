import { hideLoader, showLoader } from './Loader';
import { showMessage } from '../../helpers'
import { authenticationConstants } from '../constants';
import { login, signup, verifyEmailService, logout, syncData, resendOtp } from '../../services';
import { userSyncData } from './DataSync';
import { actionResendOTP } from './Otp';

export const userLogin = (data, onSuccess) => {
    return dispatch => {
        dispatch(showLoader());
        login(data).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'User Does not exist');
                    dispatch({ type: authenticationConstants.LOGIN_FAILURE });
                } else {
                    dispatch({ type: authenticationConstants.LOGIN_SUCCESS });
                    localStorage.setItem('user', JSON.stringify(response));
                    showMessage('success', 'User logged in successfully');
                    if(!response.is_admin){
                        dispatch(userSyncData())    //sync data
                    }
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: authenticationConstants.LOGIN_FAILURE });
                if(error.NotOTP){
                    dispatch({ type: authenticationConstants.LOGIN_OTP_VERIFY,
                                otpdata: {'name':error.NotOTP.name,
                                'mobile_number':error.NotOTP.mobile_number}
                            })
                    dispatch(actionResendOTP({
                        name: error.NotOTP.name,
                        mobile_number: error.NotOTP.mobile_number
                    }))
                }
                else if(error.non_field_errors){
                    showMessage('error', error.non_field_errors[0]);
                }
                else{
                    showMessage('error', 'Technical Error. Please try again later.');
                }
                console.log(error)
            }
        )

    }
}

export const userSignup = (data, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        signup(data).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'User Does not exist');
                    dispatch({ type: authenticationConstants.SIGNUP_FAILURE });
                } else {
                    dispatch({ type: authenticationConstants.SIGNUP_SUCCESS });
                    showMessage('success', 'User registered successfully');
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: authenticationConstants.SIGNUP_FAILURE });
                if(error.non_field_errors){
                    showMessage('error', error.non_field_errors[0])

                }else{
                    showMessage('error', 'Technical Error. Please try again later.')
                }
                console.log(error)
            }
        )
    }
}


export const userVerifyEmail = (data, onSuccess) => {
    return dispatch => {
        dispatch(showLoader());
        verifyEmailService(data).then(
            response => {
                dispatch(hideLoader());
                if (response) {
                    dispatch({ type: authenticationConstants.VERIFY_EMAIL_SUCCESS });
                    showMessage('success', 'Email Verified');
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                console.log(error)
                if (onSuccess) {
                    onSuccess()
                }
            }
        )

    }
}


export const userLogout = (onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        logout().then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Logout Failed');
                    dispatch({ type: authenticationConstants.LOGOUT_FAILURE });
                } else {
                    dispatch({ type: authenticationConstants.LOGOUT_SUCCESS });
                    localStorage.removeItem('user');
                    showMessage('success', 'Logged out successfully');
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: authenticationConstants.LOGOUT_FAILURE });
                showMessage('error', 'Logout Failed')
                console.log(error)
            }
        )
    }
}