import React from 'react';
import { Card, Col, Row } from 'antd';
import moment from 'moment';
import './vaccine-details-tile.less';

const VaccineDetailsTile = (props) => {
    const {date, age} = props;
    return (
        <Card className='vaccine-details-card'>
            <Row className={'data-wrapper'}>
                <Col>
                    <Row>
                        <Col xs={12} className='data-tile'>
                            <h3>Age</h3>
                            <span>{age}</span>
                        </Col>
                        <Col xs={12} className='data-tile align-center'>
                            <div className='date-align-right width-100'>
                                <h3>Taken On</h3>
                                <span>{moment(date).utcOffset('+0800').format('DD MMM YYYY')}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default VaccineDetailsTile;
