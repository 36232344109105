import { adminPanelConstants } from '../constants';

const initialSettings = {
    isadminDataFetched: false
};

const AdminPanel = (state = initialSettings, action) => {
    switch (action.type) {

        case adminPanelConstants.ADMINPANEL_REQUEST:
            return {
                ...state,
                isadminDataFetched: false
            };
        case adminPanelConstants.ADMINPANEL_SUCCESS:
            return {
                ...state,
                isadminDataFetched: true,
                data: action.response
            };
        case adminPanelConstants.ADMINPANEL_FAILURE:
            return {
                ...state,
                isadminDataFetched: false
            };
        
        case adminPanelConstants.ADMINPANEL_LOADING:
            return {
                ...state,
                isadminDataFetched: false
            };

        default:
            return state;
    }
};

export default AdminPanel;
