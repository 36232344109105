import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination, Tooltip, Button } from 'antd';
import {
    DownloadOutlined,
    FileSearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './invoice-table.less';

import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const InvoiceTable = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { responseData, totalCount, page, page_size } = props

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    
    useEffect(() => {

        if (responseData.length > 0){
            let cols = [
                {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date'
                }, 
                {
                    title: 'Invoice Number',
                    dataIndex: 'invoice_no',
                    key: 'invoice_no'
                }, 
                {
                    title: 'Total',
                    dataIndex: 'total',
                    key: 'total'
                },
                {
                    title: '',
                    dataIndex: 'button',
                    key: 'button',
                    render: (data) => {
                        return (
                            <div class="invoice-button-container">
                                <div class="invoice-button">
                                    <Tooltip title="Detail View">
                                        <Button shape='circle' icon={<FileSearchOutlined />}
                                        onClick={() => navigateToInvoiceDetail(data.id)}/>
                                    </Tooltip>
                                </div>

                                <div class="invoice-button">
                                    <Tooltip title="Download">
                                        <Button shape='circle' icon={<DownloadOutlined />}
                                        onClick={() => downloadInvoice(data.plato_invoice_id, data.date)} />
                                    </Tooltip> 
                                </div>
                            </div>
                        )
                    }
                }
            ]
      
            let rows = []

            let temp_id = 0
            responseData.map(val => {
                rows.push({
                    key: ++temp_id,
                    date: moment(val.date).format('DD MMM YYYY'),
                    invoice_no: val.invoice_prefix + val.invoice,
                    total: '$ ' + Number(val.total).toFixed(2),
                    button: {'plato_invoice_id': val.plato_invoice_id, 'id': val.id, 'date':val.date}
                })
            })
            
            setColumns(cols)
            setRows(rows)
        }

    }, [responseData])
    
    const changePage = (page) => {
        props.changePage(page)
    }

    const navigateToInvoiceDetail = (id) => {
        history.push("/invoice-details/" + id)
    }
    const downloadInvoice = (plato_invoice_id, date) => {
        dispatch(userDownloadPDF({'name':'invoice', 'document_id': plato_invoice_id, 'filename':`invoice_${moment(date).format('DD-MM-YYYY')}.pdf`}))
    }

    return (
        
        <>

            {responseData.length > 0 ? (
                
                <div className="table-layout-container">

                    <div className="table-layout-container-item-1">
                        <Table
                            className = "table-layout-invoice-custom"
                            columns={columns}
                            dataSource={rows}
                            pagination={false}
                        />
                    </div>

                    <div className="table-layout-container-item-2">
                        <Pagination
                            className = "table-layout-pagination"
                            onChange={changePage}
                            total={totalCount}
                            current={page}
                            pageSize={page_size}
                        />
                    </div>

                </div>

            ) : (
                <> </>
            )}

        </>
        
    );
}

export default InvoiceTable;
