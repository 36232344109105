import React, { useState, useEffect, useRef } from 'react';

import { DataLayout } from '../../layouts/data/dataLayout';
import AdminTable from '../../components/admin-table';

import { useDispatch, useSelector } from 'react-redux';
import { userAdminPanel, adminPanelLoad } from '../../appRedux/actions/AdminPanel';
import { useHistory } from 'react-router-dom';
import NoDataScreen from '../../components/no-data-screen';
import './admin-panel.less'


const AdminPanelPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const page_size = 10
    
    const { isadminDataFetched, data } = useSelector(({ adminpanel }) => adminpanel);
    const [responseData, setResponseData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [showNoData, setShowNoData] = useState(false);

    const [flag, setFlag] = useState(0)
    const [initial, setInitial] = useState(true)
    const [tableResponseData, setTableResponseData] = useState([])
    const [tablePage, setTablePage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    window.addEventListener('resize', (event) => {
        setWindowWidth(window.innerWidth)
    })

    useEffect(() => {
        dispatch(userAdminPanel({'page':page, 'page_size':page_size}))
    }, []);


    
    useEffect(() => {
        if(isadminDataFetched){
            
            if (initial == true){
                setTableResponseData(data.results)
                setInitial(false)
            }
            else if (windowWidth > 768){
                setTableResponseData(data.results)
            }

            dispatch(adminPanelLoad())
            setTotalCount(data.count)
            setShowNoData(data.results.length < 1)
        }
    }, [isadminDataFetched])

    
    useEffect(() => {
        if(windowWidth < 768 && initial == false){
            setPage((page) => page + 1)
            if(page > 1){
                if(totalCount !== 0 && (totalCount - responseData.length) > 0){
                    dispatch(userAdminPanel({'page':page, 'page_size':page_size}))
                }
            }
        }
    }, [flag])


    const changePageForTable = (page) => {
        dispatch(userAdminPanel({'page':page, 'page_size':page_size}))
        setTablePage(page)
    }


    return (
        <DataLayout
            showAdminSettings={true}
            isHomeScreen={true}
            showDownload={false}
            showFilter={false}
            showCTApadding={true}
        >
            <div className="admin-panel-header-wrapper"><h2>Admin Panel</h2></div>
            { showNoData ? <NoDataScreen/> : <AdminTable responseData={tableResponseData} totalCount={totalCount} page={tablePage} page_size={page_size} changePage={changePageForTable}/> }

        </DataLayout>
    )

}

export default AdminPanelPage;