import { config } from '../appRedux/constants/app.constants';
import { authHeader } from '../helpers';
import { handleResponse } from './handle-response';

export const getProfileData = () => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    };

    return fetch(`${config.API_URL}/patient/profile/`, requestOptions)
        .then(handleResponse)
}