import React, { useEffect } from 'react';
import { Card, Col, Row, Tooltip, Button } from 'antd';
import {
    DownloadOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import './medication-tile.less';
import medicineIcon from '../../assets/images/medicine_icon.svg'
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';

const MedicationTile = (props) => {

    const dispatch = useDispatch();
    const { medications, date, plato_invoice_id } = props;

    const downloadMedication = () => {
        dispatch(userDownloadPDF({'name':'medication', 'document_id': plato_invoice_id, 'filename':`medication_${moment().format('DD-MM-YYYY')}.pdf`}))

    }

    return (
        <Card className='medication-card'>
            <div className='title-wrapper'>
                <h3 className='date'>{moment(date).format('DD MMM YYYY')}</h3>
                    <Tooltip title="Download">
                        <Button shape='circle' icon={<DownloadOutlined />} onClick={downloadMedication} />
                    </Tooltip> 
            </div>
            {
                medications.map((value, index) => (
                    <Row className={index < 1 ? 'data-wrapper' : 'data-wrapper pt-10'}>
                        <Col xs={6} className='data-icon'>
                            <img className="med-img" src={medicineIcon} alt='medicine' />
                        </Col>
                        <Col xs={18}>
                            {
                                <Row>
                                    <Col xs={18} className='data-tile'>
                                        <span>{value.name}</span>
                                        <span className='description-text-wrapper'>{value.dosage}</span>
                                    </Col>
                                    <Col xs={6} className='data-tile align-center'>
                                        <h3>Qty</h3>
                                        <span>{value.quantity}</span>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                ))
            }
        </Card>
    );
}

export default MedicationTile;
