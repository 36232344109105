import React, { useEffect } from 'react';
import { Button, Form, Input, DatePicker, Select } from 'antd';
import moment from 'moment';
import { AuthLayout } from '../../layouts/auth/authLayout';
import { confirmForgotPass } from '../../appRedux/actions/resetpassword';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';
import { countryCodeList } from '../../helpers/sample-response';
import './confirmforgotpassword.less';



const ConfirmForgotPassword = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isPasswordConfirmed } = useSelector(({ resetpass }) => resetpass);

    //url format -> http://localhost:3000/confirm-forgotpass/MQ/5mi-e287f8a62a8a82c1b64f

    const uid = props.match.params.uid;
    const token = props.match.params.token;
    if (!uid || !token){        //redirect to login if uid or token not present
        history.push('/login')
    }
    useEffect(() => {
        if (isPasswordConfirmed) {
            history.push('/login');        //should be verifyotp
        }
    }, [isPasswordConfirmed]);

    const onFinish = (values) => {
        let data = {...values, 'uid': uid, 'token': token}
        dispatch(confirmForgotPass(data));
    };



    return (
        <AuthLayout>
            <div className='outer-container-accounts'>
            <div className='inner-container-accounts'>
                <h1 className='auth-titles'>Change Password</h1>
                <Form
                    name='confirmForgotpassword'
                    onFinish={onFinish}
                >
                    <Form.Item
                        name='new_password1'
                        rules={[
                            { required: true, message: 'Please enter your New Password' },
                            {
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|\W))(?=.*[^\w\d]).{8,}$/,
                                message: 'Password must be atleast 8 characters and must include atleast 1 uppercase, 1 lowercase, 1 number and 1 special character'
                            }
                        ]}
                    >
                        <Input.Password visibilityToggle={true} placeholder='New Password' />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            { required: true, message: 'Please enter Confirm New Password' },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('new_password1') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                        ]}
                        name='new_password2'
                    >
                        <Input.Password
                            visibilityToggle={true}
                            placeholder='Confirm New Password'
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Change Password
                    </Button>
                    </Form.Item>
                </Form>
            </div>
            </div>
        </AuthLayout>
    );
};

export default ConfirmForgotPassword;
