import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination, Tooltip, Button } from 'antd';
import {
    DownloadOutlined,
    FileSearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './covid-table.less';

import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { camelCase, startCase, toLower } from 'lodash';


const CovidTable = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { responseData, totalCount, page, page_size } = props

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    
    useEffect(() => {

        if (responseData.length > 0){
            let cols = [
                {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date'
                }, 
                {
                    title: 'Name',
                    dataIndex: 'doc_name',
                    key: 'doc_name',
                    render: (data) => {
                        return (
                            <span className='capitalize-text'>
                                {data.doc_title}
                            </span>
                        )
                    }
                },
                {
                    title: '',
                    dataIndex: 'button',
                    key: 'button',
                    render: (data) => {
                        return (
                            <div class="covid-button-container">
                                <div class="covid-button">
                                    <Tooltip title="Download">
                                        <Button shape='circle' icon={<DownloadOutlined />}
                                            onClick={() => downloadCovid(data.id, data.created_at_plato)} />
                                    </Tooltip> 
                                </div>
                            </div>
                        )
                    }
                }
            ]
      
            let rows = []

            let temp_id = 0
            responseData.map(val => {
                rows.push({
                    key: ++temp_id,
                    date: moment(val.created_at_plato).format('DD MMM YYYY'),
                    doc_name: {'doc_title':toLower(val.document_title)},
                    button: {'created_at_plato': val.created_at_plato, 'id': val.id}
                })
            })
            
            setColumns(cols)
            setRows(rows)
        }

    }, [responseData])
    
    const changePage = (page) => {
        props.changePage(page)
    }

    const downloadCovid = (covid_id, date) => {
        dispatch(userDownloadPDF({'name':'covid', 'document_id': covid_id, 'filename':`covid_${moment(date).format('DD-MM-YYYY')}.pdf`}))
    }

    return (
        
        <>

            {responseData.length > 0 ? (
                
                <div className="table-layout-container">

                    <div className="table-layout-container-item-1">
                        <Table
                            className = "table-layout-covid-custom"
                            columns={columns}
                            dataSource={rows}
                            pagination={false}
                        />
                    </div>

                    <div className="table-layout-container-item-2">
                        <Pagination
                            className = "table-layout-pagination"
                            onChange={changePage}
                            total={totalCount}
                            current={page}
                            pageSize={page_size}
                        />
                    </div>

                </div>

            ) : (
                <> </>
            )}

        </>
        
    );
}

export default CovidTable;
