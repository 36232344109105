import React, { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import moment from 'moment';
import { AuthLayout } from '../../layouts/auth/authLayout';
import { forgotPassEmail } from '../../appRedux/actions/resetpassword';
import { useDispatch, useSelector } from 'react-redux';
import './forgotpassword.less';
import {
    InfoCircleOutlined
} from '@ant-design/icons';



const ForgotPassword = () => {
    const dispatch = useDispatch();

    const onFinish = (values) => {
        dispatch(forgotPassEmail(values));
    };

    const info = () => {
        message.info('NRIC/FIN/Passport number used to register at Osler Health International');
    };



    return (
        <AuthLayout
            isBackArrow={true}
        >
            <div className="outer-container-accounts">
                <div className="inner-container-accounts">
                    <h1 className='auth-titles'>Forgot Password</h1>
                    <Form
                        name='resetpassword'
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name='email'
                            rules={[
                                { required: true, message: 'Please enter your Email' },
                                { pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter valid Email'
                                }
                            ]}
                        >
                            <Input placeholder='Email' />
                        </Form.Item>
                        

                        <Form.Item
                            name='nric'
                            rules={[
                                { required: true, message: 'Please enter NRIC/FIN/ Passport number' },
                                { min: 6, message: 'Please enter valid NRIC/FIN/ Passport number' },
                                { max: 12, message: 'Please enter valid NRIC/FIN/ Passport number' },
                                { pattern: /^[A-Z0-9]*$/, message: 'Only Uppercase Letters and Digits allowed' }
                            ]}
                        >

                            <Input placeholder='NRIC/FIN/ Passport number' suffix={
                                <InfoCircleOutlined
                                    onClick={info}
                                /> 
                            }/>
                        </Form.Item>
                        
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                Submit
                        </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    );
};

export default ForgotPassword;
