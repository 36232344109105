import React, {useEffect, useState} from 'react';

import { DataLayout } from '../../layouts/data/dataLayout';
import { Form, Col, Row, Button } from 'antd';
import { userLogout } from '../../appRedux/actions/Authentication';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import userIcon from '../../assets/images/account_user_icon.svg';
import {
    RightOutlined
} from '@ant-design/icons';
import './account.less';
import { userProfile } from '../../appRedux/actions/Profile';
import moment from 'moment';
import { startCase, toLower } from 'lodash';


const AccountPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { isUserLoggedOut } = useSelector(({ auth }) => auth);
    const { isProfileFetched, data } = useSelector(({ profile }) => profile);
    const [profileInfo, setProfileInfo] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    window.addEventListener('resize', (event) => {
        setWindowWidth(window.innerWidth)
    })

    useEffect(() => {
        dispatch(userProfile())
    }, []);

    useEffect(() =>{
        if (isProfileFetched){
            let profileData = data[0];
            setProfileInfo(
                [
                    {labelName: 'Identity Number', labelVal: profileData.nric},
                        {labelName: 'Email', labelVal: profileData.email},
                        {labelName: 'Mobile', labelVal: profileData.mobile_number},
                        {labelName: 'Date of Birth', labelVal: moment(profileData.dob).format('DD/MM/YYYY')},
                        {labelName: 'Marital Status', labelVal: profileData.marital_status},
                        {labelName: 'Sex', labelVal: profileData.sex},
                        {labelName: 'Nationality', labelVal: profileData.nationality},
                        {labelName: 'Occupation', labelVal: profileData.occupation},
                        {labelName: 'Address', labelVal: profileData.address},
                        {labelName: 'Postal Code', labelVal: profileData.postal_code},
                        {labelName: 'Unit No', labelVal: profileData.unit_no}
                ]
            )
        }
    }, [isProfileFetched])


    const logoutUser = () => {
        dispatch(userLogout(()=>{
            history.go(0)
        }))
    }

    const extraSection = [
                    {labelName:'Change Password', redirectUrl:'/resetpass'},
                    {labelName:'Terms & Conditions', redirectUrl: '/home'}
                ]

    return (
        <DataLayout
            title='Account'
            isHomeScreen={false}
            showUser={false}
            showDownload={false}
            showFilter={false}
            showCTApadding={true}
        >
            
            <div className='width-100 user-icon-section-wrapper'>
                <Row className='user-name-align-center'>
                    <Col>
                        <img className='user-icon-wrapper' src={userIcon} alt='user-icon' />
                    </Col>
                    <Col>
                        <span className='name-text-wrapper'>{data&&data[0].name?startCase(toLower(data[0].name)):null}</span>
                    </Col>
                </Row>
            </div>

            <div className='account-details-text-wrapper'>
                <h3>Account Details</h3>
            </div>
            <div className='width-100 section-wrapper'>
                {
                    profileInfo.map(value=>(
                            <Row className='span-text-wrapper'>
                                {windowWidth > 768 ? (
                                    <Col xs={6}>
                                        <span>{value.labelName}</span>
                                    </Col>
                                ) : (
                                    <Col xs={12}>
                                        <span>{value.labelName}</span>
                                    </Col>
                                ) }
                            
                                <Col xs={12} className='span-text-right'>
                                    {value.labelName === 'Occupation' || value.labelName === 'Address' ?
                                    <span className='capitalize-text'>
                                        {value.labelVal?value.labelVal:'-'}
                                    </span>
                                    :<span>{value.labelVal?value.labelVal:'-'}</span>
                                    }
                                </Col>
                            </Row>
                        )
                    )
                }
            </div>
            <div className='width-100 user-icon-section-wrapper hover-links-wrapper btn-link' onClick={() => history.push('/resetpass')}>
                <Row>
                    <Col xs={12}>
                        <span>Change Password</span>
                    </Col>
                    <Col xs={12} className='align-arrow-right'>
                        <RightOutlined />
                    </Col>
                </Row>
            </div>
            <div className='width-100 hover-links-wrapper user-icon-section-wrapper' onClick={() => history.push('/terms-condition')}>
                <Row>
                    <Col xs={12}>
                        <span>Terms & Conditions</span>
                    </Col>
                    <Col xs={12} className='align-arrow-right'>
                        <RightOutlined />
                    </Col>
                </Row>
            </div>
            <div className='width-100 hover-links-wrapper' onClick={() => history.push('/privacy-policy')}>
                <Row>
                    <Col xs={12}>
                        <span>Privacy Policy</span>
                    </Col>
                    <Col xs={12} className='align-arrow-right'>
                        <RightOutlined />
                    </Col>
                </Row>
            </div>
            <div className='version-text-wrapper'>
                <span>
                    Version 1.0.1
                </span>
            </div>
            <div className='button-container'>
                <Button onClick={logoutUser} className='logout-button' type='primary' htmlType='submit'>
                    Logout
                </Button>
            </div>
            <div className='copyright-text-wrapper'>
                <span>
                © Copyright 2021 Osler Health International
                </span>
            </div>


        </DataLayout>
    )

}

export default AccountPage;