import React, { useEffect, useState } from 'react';
import { Button, Form, Input, DatePicker, Select, message } from 'antd';
import moment from 'moment';
import { AuthLayout } from '../../layouts/auth/authLayout';
import { userSignup } from '../../appRedux/actions/Authentication';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';
import { countryCodeList } from '../../helpers/sample-response';
import './signup.less';
import {
    InfoCircleOutlined
} from '@ant-design/icons';


const { Option } = Select
const sortedCountryCodeList = sortBy(countryCodeList, ['code'])

const SignUp = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isUserSignedUp } = useSelector(({ auth }) => auth);
    const [nameState, setName] = useState('');
    const [mobileState, setMobile] = useState('');
    const [mobprefixState, setMobPrefix] = useState('');
    const [mobwopreState, setMobWoPre] = useState(''); //Mobile number without prefix

    useEffect(() => {
        if (isUserSignedUp) {
            history.push(
                {pathname:'/otp',
                state: {'mobile_number': mobileState, 'name': nameState, 'prefix':mobprefixState, 'mobwoprefix': mobwopreState}
            });        //should be verifyotp
        }
    }, [isUserSignedUp]);

    const onFinish = (values) => {
        let formattedDob = moment(values.dob).format('YYYY-MM-DD');
        let formattedMobile = values.prefix + values.mobile_number
        let data = { 
            name: values.name,
            nric: values.nric,
            dob: formattedDob,
            mobile_number: formattedMobile,
            password: values.password
        }
        setName(data.name);
        setMobile(data.mobile_number)
        setMobPrefix(values.prefix)
        setMobWoPre(values.mobile_number)   //Mobile Number without prefix
        dispatch(userSignup(data));
    };

    const navigateToLogin = () => {
        history.push('/login')
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    };

    const prefixSelector = (
        <Form.Item
            rules={[
                { required: true, message: 'Please select country code' },
            ]}
            name='prefix'
            noStyle>
            <Select
                style={{ width: 100 }}
                showSearch
                optionFilterProp='children'
                filterOption={true}
            >
                {
                    sortedCountryCodeList.map(value => <Option key={value.code} value={value.code}>{value.code}</Option>)
                }
            </Select>
        </Form.Item>
    );

    const info = () => {
        message.info('NRIC/FIN/Passport number used to register at Osler Health International');
    };



    return (
        <AuthLayout>
            <div className="outer-container-accounts">
                <div className="inner-container-accounts">
                    <h1 className='auth-titles'>Sign Up</h1>
                    <Form
                        name='signup'
                        onFinish={onFinish}
                        initialValues={{
                            prefix: '+65',
                        }}
                    >
                        <Form.Item
                            name='nric'
                            rules={[
                                { required: true, message: 'Please enter NRIC/FIN/ Passport number' },
                                { min: 6, message: 'Please enter valid NRIC/FIN/ Passport number' },
                                { max: 12, message: 'Please enter valid NRIC/FIN/ Passport number' },
                                { pattern: /^[A-Z0-9]*$/, message: 'Only Uppercase Letters and Digits allowed' }
                            ]}
                        >
                            <Input placeholder='NRIC/FIN/Passport number'
                                suffix={
                                    <InfoCircleOutlined
                                        onClick={info}
                                    /> 
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            name='name'
                            rules={[
                                { required: true, message: 'Please enter your Name' },
                                { max: 255, message: 'Name cannot be more than 255 characters' }
                            ]}
                        >
                            <Input placeholder='Name' />
                        </Form.Item>
                        <Form.Item
                            name='dob'
                            rules={[
                                { required: true, message: 'Please enter your Date Of Birth' },
                            ]}
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                disabledDate={disabledDate}
                                placeholder='Date of Birth'
                            />
                        </Form.Item>
                        <Form.Item
                            name='mobile_number'
                            rules={[
                                { required: true, message: 'Please enter your Mobile Number' },
                                { min: 6, message: 'Mobile number cannot be less than 6 digits' },
                                { max: 20, message: 'Mobile number cannot be more 20 digits' }
                            ]}
                        >
                            <Input placeholder='Mobile' type='number' addonBefore={prefixSelector} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name='password'
                            rules={[
                                { required: true, message: 'Please enter your Password' },
                                {
                                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|\W))(?=.*[^\w\d]).{8,}$/,
                                    message: 'Password must be atleast 8 characters and must include atleast 1 uppercase, 1 lowercase, 1 number and 1 special character'
                                }
                            ]}
                        >
                            <Input.Password visibilityToggle={true} placeholder='Password' />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                { required: true, message: 'Please enter Confirm Password' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                }),
                            ]}
                            name='confirm_password'
                        >
                            <Input.Password
                                visibilityToggle={true}
                                placeholder='Confirm Password'
                            />
                        </Form.Item>
                        
                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                Sign Up
                        </Button>
                        </Form.Item>
                        <p className='hover-links-wrapper' onClick={navigateToLogin}>
                            Already have an account? <span className='btn-link ml-5'>Login!</span>
                        </p>
                    </Form>
                </div>
            </div>
        </AuthLayout>
    );
};

export default SignUp;
