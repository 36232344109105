export const authenticationConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_REQUEST: 'SIGNUP_REQUEST',
    SIGNUP_FAILURE: 'SIGNUP_FAILURE',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_FAILURE: 'VERIFY_EMAIL_FAILURE',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
    LOGIN_OTP_VERIFY: 'LOGIN_OTP_VERIFY'
};

export const roleConstants = {
    ADMIN_ROLE: true,
    USER_ROLE: false
}