import React from 'react';
import './no-data-screen.less';
import nodataIcon from '../../assets/images/no_data.svg';

const NoDataScreen = () => {
    return (
        <div className='no-data-wrapper'>
            <img className='no-data-icon-wrapper' src={nodataIcon} alt='no-data' />
            <span>No Data Available</span>
            <span className='no-data-text-wrapper'>There is no data to show you right now.</span>
        </div>
    );
}

export default NoDataScreen;
