import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { AuthLayout } from '../../layouts/auth/authLayout';
import { userVerifyEmail } from '../../appRedux/actions/Authentication';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const VerifyEmailPage = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { isEmailVerified } = useSelector(({ auth }) => auth);
    const key = props.match.params.key;
    // http://localhost:3000/verify-email/Mg:1kptUp:43WealNX0lC2-u-e-7Yc85SC0KE
    useEffect(() => {
        dispatch(userVerifyEmail({'key':key}, () => {
            history.push('/login')
        }))
    }, []);

    


    return null

}

export default VerifyEmailPage;