import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination, Tooltip, Button } from 'antd';
import {
    DownloadOutlined,
    FileSearchOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './pathology-table.less';

import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { camelCase, startCase, toLower } from 'lodash';


const PathologyTable = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { responseData, totalCount, page, page_size } = props

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    
    useEffect(() => {

        if (responseData.length > 0){
            let cols = [
                {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date'
                }, 
                {
                    title: 'Name',
                    dataIndex: 'doc_name',
                    key: 'doc_name',
                    render: (data) => {
                        return (
                            <div className='pathology-name-wrapper'>
                                {
                                    data.down_list.map((value, idx) => (
                                        <div className='pathology-name-button-container'>
                                            <div className='capitalize-text'>
                                                <span>{toLower(value)}</span>
                                            </div>
                                            <div className="pathology-button">
                                                <Tooltip title="Download">
                                                    <Button shape='circle' icon={<DownloadOutlined />} size={"small"}
                                                        onClick={() => downloadPathology(data.id, value, idx)} />
                                                </Tooltip> 
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    }
                }
            ]

            let rows = []

            let temp_id = 0
            responseData.map(val => {
                rows.push({
                    key: ++temp_id,
                    date: moment(val.created_at_plato).format('DD MMM YYYY'),
                    doc_name: {'created_at_plato': val.created_at_plato, 'id': val.id, 'down_list':val.document_list},
                })
            })
            
            setColumns(cols)
            setRows(rows)
        }

    }, [responseData])
    
    const changePage = (page) => {
        props.changePage(page)
    }

    const downloadPathology = (pathology_id, name, idx) => {
        dispatch(userDownloadPDF({'name':'pathology_report', 'document_id': pathology_id,
                                'filename':`${name}.pdf`,
                                'report_idx': idx}))
    }

    return (
        
        <>

            {responseData.length > 0 ? (
                
                <div className="table-layout-container">

                    <div className="table-layout-container-item-1">
                        <Table
                            className = "table-layout-pathology-custom"
                            columns={columns}
                            dataSource={rows}
                            pagination={false}
                        />
                    </div>

                    <div className="table-layout-container-item-2">
                        <Pagination
                            className = "table-layout-pagination"
                            onChange={changePage}
                            total={totalCount}
                            current={page}
                            pageSize={page_size}
                        />
                    </div>

                </div>

            ) : (
                <> </>
            )}

        </>
        
    );
}

export default PathologyTable;
