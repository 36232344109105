export const resetPassConstants = {
    RESET_PASS_REQUEST: 'RESET_PASS_REQUEST',
    RESET_PASS_SUCCESS: 'RESET_PASS_SUCCESS',
    RESET_PASS_FAILURE: 'RESET_PASS_FAILURE'
};

export const confirmForgotPassConstants = {
    CONFIRM_FORGOT_PASS_REQUEST: 'CONFIRM_FORGOT_PASS_REQUEST',
    CONFIRM_FORGOT_PASS_SUCCESS: 'CONFIRM_FORGOT_PASS_SUCCESS',
    CONFIRM_FORGOT_PASS_FAILURE: 'CONFIRM_FORGOT_PASS_FAILURE'
}