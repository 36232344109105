import { Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import {
    ArrowDownOutlined
} from '@ant-design/icons';
import { DataLayout } from '../../layouts/data/dataLayout';
import './invoice-details.less';
import downloadIcon from '../../assets/images/download_icon.svg';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { userInvoiceDetail, invoiceDetailLoaded } from '../../appRedux/actions/InvoiceDetail';
import { useHistory } from 'react-router-dom';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';



const InvoiceDetailsPage = (props) => {

    
    const dispatch = useDispatch();
    const history = useHistory();
    const invoice_id = props.match.params.id;
    const { isInvoiceDetailFetched, data } = useSelector(({ invoicedetail }) => invoicedetail);
    const [responseData, setResponseData] = useState({});

    useEffect(() => {
        if(invoice_id){
            dispatch(userInvoiceDetail(invoice_id))
        }
    }, []);

    useEffect(() => {
        if(isInvoiceDetailFetched){
            setResponseData(data)
            dispatch(invoiceDetailLoaded())
        }
    }, [isInvoiceDetailFetched])

    const downloadInvoice = () => {
        dispatch(userDownloadPDF({'name':'invoice', 'document_id': responseData.plato_invoice_id, 'filename':`invoice_${moment(responseData.date).format('DD-MM-YYYY')}.pdf`}))
    }


    return (
        <DataLayout
            title={'Invoice Details'}
            showDownload={false}
            showFilter={false}
            hideChildrenPadding={true}
            downloadData={downloadInvoice}
        >
            <div className='account-page width-100'>
                <div className='summary-div-wrapper'>
                    <Row className='span-text-wrapper'>
                        <Col xs={12}>
                            <span className='date'>
                                {moment(responseData.date).format('DD MMM YYYY')}
                            </span>
                        </Col>
                        <Col xs={12} className='align-col-right'>
                            <span>
                                {responseData.invoice_prefix}{responseData.invoice}
                            </span>
                        </Col>
                    </Row>
                    <Row className='span-text-wrapper'>
                        <Col className='width-100'>
                            <Row>
                                <span className='total-label-wrapper'>Total</span>
                            </Row>
                            <Row>
                                <Col xs={18} className='total-price-wrapper'>
                                    <span>
                                        {'$' + Number(responseData.total).toFixed(2)}
                                    </span>
                                </Col>
                                <Col xs={6} className='align-col-center'>
                                    <img className='hover-links-wrapper' src={downloadIcon} onClick={downloadInvoice} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='items-amount-wrapper'>
                    <Row>
                        <Col xs={12}>
                            <h3>Items</h3>
                        </Col>
                        <Col xs={12} className='align-col-right'>
                            <h3>Amount</h3>
                        </Col>
                    </Row>
                </div>
                <div className='items-wrapper'>
                    {
                        responseData.invoice_items ?
                        responseData.invoice_items.map((value, index) => (
                        <Row className='apply-border-bottom row-top-padding'>
                            <Col xs={18}>
                                <Row>
                                    <span className='detail-text-wrapper'>
                                        {value.name}
                                    </span>
                                </Row>
                                <Row>
                                    <span className='detail-text-wrapper'>
                                        {value.quantity}*{'$' + Number(value.unit_price).toFixed(2)}
                                    </span>
                                </Row>
                                <Row>
                                    <span className='detail-text-wrapper'>
                                        Discount - {Number(value.discount).toFixed(2)}%
                                    </span>
                                </Row>
                            </Col>
                            <Col xs={6} className='align-col-right font-subtotal-amount-wrapper'>
                                <span>
                                    ${Number(value.subtotal).toFixed(2)}
                                </span>
                            </Col>
                        </Row>)
                        )
                        : null
                    }
                </div>
                <div className='subtotal-wrapper'>
                    <Row>
                        <Col xs={16} className='align-labels-right'>
                            <h4>Sub total</h4>
                        </Col>
                        <Col xs={8} className='align-amount-right'>
                            <span>
                                ${Number(responseData.sub_total).toFixed(2)}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={16} className='align-labels-right'>
                            <h4>
                                GST
                            </h4>
                        </Col>
                        <Col xs={8} className='align-amount-right'>
                            <span>
                                ${(Number(responseData.total) - (Number(responseData.sub_total) + Number(responseData.adj_amount))).toFixed(2)}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={16} className='align-labels-right'>
                            <h4>Adjustment</h4>
                        </Col>
                        <Col xs={8} className='align-amount-right'>
                            <span>
                                ${Math.abs(Number(responseData.adj_amount).toFixed(2))}
                            </span>
                        </Col>
                    </Row>
                    <div className='apply-border-bottom'>
                    </div>
                    <div className='total-section-wrapper'>
                        <Row>
                            <Col xs={16} className='align-labels-right'>
                                <h3>Total</h3>
                            </Col>
                            <Col xs={8} className='align-amount-right'>
                                <span>
                                    ${Number(responseData.total).toFixed(2)}
                                </span>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </DataLayout>
    )

}

export default InvoiceDetailsPage;