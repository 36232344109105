import React, { useState, useEffect, useRef } from 'react';

import { DataLayout } from '../../layouts/data/dataLayout';
import MedicationTile from '../../components/medication-tile';

import sampleResponse from '../../sample-response/medication.json'
import { useDispatch, useSelector } from 'react-redux';
import { userMedications, medicationLoad } from '../../appRedux/actions/Medication';
import { useHistory } from 'react-router-dom';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import moment from 'moment';
import NoDataScreen from '../../components/no-data-screen';


const MedicationPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { isMedicationFetched, data } = useSelector(({ medication }) => medication);
    const [responseData, setResponseData] = useState([]);
    const [page, setPage] = useState(1);
    const loader = useRef(null);
    const [isChildRendered, setChildRendered] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [showNoData, setShowNoData] = useState(false);

    useEffect(() => {
        dispatch(userMedications({'page':page, 'page_size':10}))
    }, []);

    useEffect(() => {
        if(isChildRendered){
            var options = {
                root: null,
                rootMargin: "20px",
                threshold: 1.0
            };
            const observer = new IntersectionObserver(handleObserver, options);
            if (loader.current) {
                observer.observe(loader.current)
            }
        }
    }, [isChildRendered])

    useEffect(() => {
        if(isMedicationFetched){
            setResponseData(responseData.concat(data.results))
            dispatch(medicationLoad())
            setChildRendered(true)
            setTotalCount(data.count)
            setShowNoData(data.results.length < 1)
        }
    }, [isMedicationFetched])

    useEffect(() => {
        if(page > 1){
            if(totalCount !== 0 && (totalCount - responseData.length) > 0){
                dispatch(userMedications({'page':page, 'page_size':10}))
            }
        }
    }, [page])

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            if(responseData.length < totalCount)
                setPage((page) => page + 1)
        }
    }


    const downloadMedication = () => {
        dispatch(userDownloadPDF({'name':'medication', 'filename':`medication_${moment().format('DD-MM-YYYY')}.pdf`}))
    }

    return (
        <DataLayout
            title='Medication'
            showUser={false}
            showDownload={responseData.length > 0 ? true :false}
            showFilter={false}
            downloadData={downloadMedication}
        >
            {
                showNoData ? <NoDataScreen/>
                :responseData
                    .map(val => <MedicationTile {...val} />)
            }
            {
                isChildRendered ? 
                <div className="loading" ref={loader}>
                </div>
                :null
            }
        </DataLayout>
    )

}

export default MedicationPage;