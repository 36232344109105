import { invoiceDetailsConstants } from '../constants';

const initialSettings = {
    isInvoiceDetailFetched: false,
    data: {}
};

const InvoiceDetail = (state = initialSettings, action) => {
    switch (action.type) {

        case invoiceDetailsConstants.INVOICE_DETAIL_REQUEST:
            return {
                ...state,
                isInvoiceDetailFetched: false
            };
        case invoiceDetailsConstants.INVOICE_DETAIL_SUCCESS:
            return {
                ...state,
                isInvoiceDetailFetched: true,
                data: action.response
            };
        case invoiceDetailsConstants.INVOICE_DETAIL_FAILURE:
            return {
                ...state,
                isInvoiceDetailFetched: false
            };
        case invoiceDetailsConstants.INVOICE_DETAIL_LOADED:
            return {
                ...state,
                isInvoiceDetailFetched: false,
                data: {}
            };

        default:
            return state;
    }
};

export default InvoiceDetail;
