import { resetPassConstants, confirmForgotPassConstants } from '../constants';

const initialSettings = {
    isPasswordReset: false,
    isPasswordConfirmed: false      //forgot password confirmation
};

const ResetPassword = (state = initialSettings, action) => {
    switch (action.type) {

        case resetPassConstants.RESET_PASS_REQUEST:
            return {
                ...state,
                isPasswordReset: false
            };
        case resetPassConstants.RESET_PASS_SUCCESS:
            return {
                ...state,
                isPasswordReset: true
            };
        case resetPassConstants.RESET_PASS_FAILURE:
            return {
                ...state,
                isPasswordReset: false
            };
        
        case confirmForgotPassConstants.CONFIRM_FORGOT_PASS_REQUEST:
            return {
                ...state,
                isPasswordConfirmed: false
            };
        case confirmForgotPassConstants.CONFIRM_FORGOT_PASS_SUCCESS:
            return {
                ...state,
                isPasswordConfirmed: true
            };
        case confirmForgotPassConstants.CONFIRM_FORGOT_PASS_FAILURE:
            return {
                ...state,
                isPasswordConfirmed: false
            };

        default:
            return state;
    }
};

export default ResetPassword;
