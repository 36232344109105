import React from 'react';
import { Card, Col, Row, Button, Tooltip } from 'antd';
import {
    DownloadOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './covid-tile.less';
import { useHistory } from 'react-router-dom';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';
import { toLower } from 'lodash';

const CovidTile = (props) => {
    const { created_at_plato, document_title, id, dob} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const downloadNote = () => {
        dispatch(userDownloadPDF({'name':'covid', 'document_id': id, 'filename':`covid_${moment(created_at_plato).format('DD-MM-YYYY')}.pdf`}))
    }
    return (
        <Card className='covid-card'>
            <div className='title-wrapper'>
                <h3 className='date date-section'>{moment(created_at_plato).format('DD MMM YYYY')}</h3>
                <Button shape='circle' icon={<DownloadOutlined />} onClick={downloadNote} />
            </div>
            <div className='hover-links-wrapper'>
            <div className='covid-tile-row'>
                <div className='covid-tile-elm'><h3 className='capitalize-text'>{toLower(document_title)}</h3></div>
            </div>
            </div>
        </Card>
    );
}

export default CovidTile;
