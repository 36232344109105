import React from 'react';
import { Card, Col, Row, Button } from 'antd';
import {
    DownloadOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './invoice-tile.less';
import { useHistory } from 'react-router-dom';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';

const InvoiceTile = (props) => {
    const { date, invoice_prefix, invoice, total, plato_invoice_id, id} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const downloadInvoice = () => {
        dispatch(userDownloadPDF({'name':'invoice', 'document_id': plato_invoice_id, 'filename':`invoice_${moment(date).format('DD-MM-YYYY')}.pdf`}))
    }
    return (
        <Card className='invoice-card'>
            <div className='title-wrapper'>
                <h3 className='date date-section'>{moment(date).format('DD MMM YYYY')}</h3>
                    <Button shape='circle' icon={<DownloadOutlined />} onClick={downloadInvoice} />
            </div>
            <div className='hover-links-wrapper' onClick={() => history.push(`/invoice-details/${id}`)}>
            <div className='invoice-tile-row'>
                <div className='invoice-tile-elm'><h3>Invoice Number</h3></div>
                <div className='invoice-tile-elm'><span>{invoice_prefix}{invoice}</span></div>
            </div>
            {/* <div className='invoice-tile-row'>
                <h3>Total</h3>
                <span>{total}</span>
            </div> */}
            {/* <Row className='invoice-desc'>
                <Col xs={8}>
                    <h3>Description</h3>
                </Col>
                <Col xs={16} className='pt-5'>
                    {
                        data.invoice_items.map(data => (
                            <Row className='mb-15'>
                                <Col xs={24} className='desc-item'>
                                    <span>{data.name}</span>
                                </Col>
                                <Col xs={24} className='desc-item'>
                                    <span>{data.quantity + ' * $' + Number(data.unit_price)}</span>
                                </Col>
                            </Row>
                        ))
                    }
                </Col>
            </Row> */}
            <div className='invoice-total-row'>
                <div className='invoice-total-elm'><h3>Total</h3></div>
                <div className='invoice-total-elm'><span> {'$' + Number(total).toFixed(2)}</span></div>
            </div>
            </div>
        </Card>
    );
}

export default InvoiceTile;
