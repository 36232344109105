import { profileConstants } from '../constants';

const initialSettings = {
    isProfileFetched: false
};

const Profile = (state = initialSettings, action) => {
    switch (action.type) {

        case profileConstants.PROFILE_REQUEST:
            return {
                ...state,
                isProfileFetched: false
            };
        case profileConstants.PROFILE_SUCCESS:
            return {
                ...state,
                isProfileFetched: true,
                data: action.response
            };
        case profileConstants.PROFILE_FAILURE:
            return {
                ...state,
                isProfileFetched: false
            };

        default:
            return state;
    }
};

export default Profile;
