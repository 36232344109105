import { keyMeasurementsConstants } from '../constants';

const initialSettings = {
    isKeyMeasurementFetched: false
};

const KeyMeasurement = (state = initialSettings, action) => {
    switch (action.type) {

        case keyMeasurementsConstants.KEYMEASUREMENTS_REQUEST:
            return {
                ...state,
                isKeyMeasurementFetched: false
            };
        case keyMeasurementsConstants.KEYMEASUREMENTS_SUCCESS:
            return {
                ...state,
                isKeyMeasurementFetched: true,
                data: action.response
            };
        case keyMeasurementsConstants.KEYMEASUREMENTS_FAILURE:
            return {
                ...state,
                isKeyMeasurementFetched: false
            };
        
        case keyMeasurementsConstants.KEYMEASUREMENTS_LOADING:
            return {
                ...state,
                isKeyMeasurementFetched: false
            };

        default:
            return state;
    }
};

export default KeyMeasurement;
