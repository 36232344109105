import React from 'react';
import { Card, Col, Row, Button } from 'antd';
import {
    DownloadOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './measurement-tile.less';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';

const KeyMeasurementTile = (props) => {

    const dispatch = useDispatch();
    const { date, dob, weight, height, bp, clothed, headcirc, id } = props;
    const processAge = () => {
        if(dob){
            let key_measure_date = moment(date).utcOffset('+0800')
            let diffDuration = moment.duration(key_measure_date.diff(dob));
            let yrs = diffDuration.years()
            let mth = diffDuration.months()
            let dys = diffDuration.days()
            if(yrs > 0 && mth > 0){
                return yrs + 'y ' + mth + 'm'
            }else if(yrs === 0 && mth > 0){
                return mth + 'm'
            }else if(yrs === 0 && mth === 0 && dys > 0){
                return dys + ' days'
            }
            else if(yrs === 0 && mth === 0 && dys === 0){
                return '1 day'
            }
        }
        return '-'
    }
    const downloadMedication = () => {
        dispatch(userDownloadPDF({'name':'key_measurement', 'document_id': id, 'filename':`key-measurement${moment().format('DD-MM-YYYY')}.pdf`}))
    }
    return (
        <Card className='measurement-tile-card'>
            <div className='title-wrapper'>
                <h3 className='date'>{moment(date).utcOffset('+0800').format('DD MMM YYYY')}</h3>
                    <Button shape='circle' icon={<DownloadOutlined />} onClick={downloadMedication} />
            </div>
            <Row>
                <Col xs={8} className='data-tile'>
                    <h3>Age</h3>
                    {processAge()}
                </Col>
                <Col xs={8} className='data-tile'>
                    <h3>Weight</h3>
                    <span>{weight ? Number(weight).toFixed(1) + ' Kg' : '-'}</span>
                </Col>
                <Col xs={8} className='data-tile'>
                    <h3>Height</h3>
                    <span>{height ? Number(height).toFixed(1) + ' cm' : '-'}</span>
                </Col>
                <Col xs={8} className='data-tile'>
                    <h3>BP</h3>
                    <span>{bp ? bp + ' mmHg' : '-'}</span>
                </Col>
                <Col xs={8} className='data-tile'>
                    <h3>BMI</h3>
                    <span>{weight && height ? Number(Number(weight) / (Number(height) * Number(height) * 0.01 * 0.01)).toFixed(2) : '-'}</span>
                </Col>
                {
                    moment().diff(dob, 'months') < 6 ?
                        <Col xs={8} className='data-tile'>
                            <h3>Clothed</h3>
                            <span>{clothed ? 'Yes' : 'No'}</span>
                        </Col>
                        : null
                }

                <Col xs={16} className='data-tile'>
                    <h3>Head Circumference</h3>
                    <span>{headcirc ? Number(headcirc).toFixed(2) : '-'}</span>
                </Col>
            </Row>
        </Card>
    );
}

export default KeyMeasurementTile;
