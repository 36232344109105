import { vaccineConstants } from '../constants';

const initialSettings = {
    isVaccineFetched: false
};

const Vaccine = (state = initialSettings, action) => {
    switch (action.type) {

        case vaccineConstants.VACCINE_REQUEST:
            return {
                ...state,
                isVaccineFetched: false
            };
        case vaccineConstants.VACCINE_SUCCESS:
            return {
                ...state,
                isVaccineFetched: true,
                data: action.response
            };
        case vaccineConstants.VACCINE_FAILURE:
            return {
                ...state,
                isVaccineFetched: false
            };

        case vaccineConstants.VACCINE_LOADING:
            return {
                ...state,
                isVaccineFetched: false
            };

        default:
            return state;
    }
};

export default Vaccine;
