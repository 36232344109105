import { appointReqConstants } from '../constants';

const initialSettings = {
    isAppointReqSent: false
};

const AppointReq = (state = initialSettings, action) => {
    switch (action.type) {

        case appointReqConstants.APPOINTREQ_REQUEST:
            return {
                ...state,
                isAppointReqSent: false
            };
        case appointReqConstants.APPOINTREQ_SUCCESS:
            return {
                ...state,
                isAppointReqSent: true,
                data: action.response
            };
        case appointReqConstants.APPOINTREQ_FAILURE:
            return {
                ...state,
                isAppointReqSent: false
            };

        default:
            return state;
    }
};

export default AppointReq;
