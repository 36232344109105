import React, { Component } from 'react';
import { Route, Redirect, Switch, BrowserRouter as Router } from 'react-router-dom';
import { Auth } from '../helpers/Auth';
import KeyMeasurementPage from '../pages/key-measurements';
import LoginPage from '../pages/login';
import SignUp from '../pages/signup';
import OTP from '../pages/otp';
import ResetPassword from '../pages/resetpassword';
import ConfirmForgotPassword from '../pages/confirmforgotpassword';
import MedicationPage from '../pages/medication';
import HomePage from '../pages/homepage';
import VaccinePage from '../pages/vaccination';
import VaccineDetailsPage from '../pages/vaccine-details';
import AccountPage from '../pages/accounts';
import InvoicePage from '../pages/invoices';
import InvoiceDetailsPage from '../pages/invoice-details';
import ForgotPassword from '../pages/forgotpassword';
import VerifyEmailPage from '../pages/verify-email';
import AdminPanelPage from '../pages/admin-panel';
import {roleConstants} from '../appRedux/constants';
import CovidPage from '../pages/covidpage';
import PrivacyPolicyPage from '../pages/privacy-policy';
import TermsConditionPage from '../pages/terms-condition';
import PathologyPage from '../pages/pathology';

const AuthenticatedKeyMeasurementPage = Auth(KeyMeasurementPage, [roleConstants.USER_ROLE]);
const AuthenticatedHomePage = Auth(HomePage, [roleConstants.USER_ROLE]);
const AuthenticatedAccountPage = Auth(AccountPage, [roleConstants.USER_ROLE]);
const AuthenticatedInvoicePage = Auth(InvoicePage, [roleConstants.USER_ROLE]);
const AuthenticatedInvoiceDetailsPage = Auth(InvoiceDetailsPage, [roleConstants.USER_ROLE]);
const AuthenticatedVaccinePage = Auth(VaccinePage, [roleConstants.USER_ROLE]);
const AuthenticatedVaccineDetailsPage = Auth(VaccineDetailsPage, [roleConstants.USER_ROLE]);
const AuthenticatedResetPassPage = Auth(ResetPassword, [roleConstants.USER_ROLE, roleConstants.ADMIN_ROLE]);
const AuthenticatedMedicationPage = Auth(MedicationPage, [roleConstants.USER_ROLE])
const AuthenticatedAdminPanelPage = Auth(AdminPanelPage, [roleConstants.ADMIN_ROLE])
const AuthenticatedCovidPage = Auth(CovidPage, [roleConstants.USER_ROLE]);
const AuthenticatedPathologyReport = Auth(PathologyPage, [roleConstants.USER_ROLE]);

const NotFoundRedirect = () => <Redirect to='/login' />;

class AppRouter extends Component {
    render() {
        const forgotpassuidRegex = '([0-9A-Za-z_\-]+)'
        const forgotpasstokenRegex = '([0-9A-Za-z]{1,13}-[0-9A-Za-z]{1,20})?'
        const confirmforgotpassRoute = `/confirm-forgotpass/:uid${forgotpassuidRegex}/:token${forgotpasstokenRegex}`
        const verifyEmailRegex = '(.{1,})?'
        const verifyEmailRoute = `/verify-email/:key${verifyEmailRegex}`
        const onlynumRegex = '([0-9]+)?'
        const invoiceDetailRoute = `/invoice-details/:id${onlynumRegex}`
        return (
            <Router>
                <Switch >
                    <Route exact path='/' render={() => <Redirect to='/login' />} />
                    <Route path='/login' component={LoginPage} />
                    <Route path='/key-measurements' component={AuthenticatedKeyMeasurementPage} />
                    <Route path='/signup' component={SignUp} />
                    <Route path='/otp' component={OTP} />
                    <Route path='/resetpass' component={AuthenticatedResetPassPage} />
                    <Route path={confirmforgotpassRoute} component={ConfirmForgotPassword} />
                    <Route path='/medications' component={AuthenticatedMedicationPage} />
                    <Route path='/home' component={AuthenticatedHomePage} />
                    <Route path='/vaccine' component={AuthenticatedVaccinePage} />
                    <Route path='/vaccine-details' component={AuthenticatedVaccineDetailsPage} />
                    <Route path='/account' component={AuthenticatedAccountPage} />
                    <Route path='/invoices' component={AuthenticatedInvoicePage} />
                    <Route path={invoiceDetailRoute} component={AuthenticatedInvoiceDetailsPage} />
                    <Route path='/forgotpass' component={ForgotPassword} />
                    <Route path={verifyEmailRoute} component={VerifyEmailPage} />
                    <Route path='/admin-panel' component={AuthenticatedAdminPanelPage} />
                    <Route path='/covid-notes' component={AuthenticatedCovidPage} />
                    <Route path='/privacy-policy' component={PrivacyPolicyPage} />
                    <Route path='/terms-condition' component={TermsConditionPage} />
                    <Route path='/results' component={AuthenticatedPathologyReport} />
                    <Route component={NotFoundRedirect} />
                </Switch>
            </Router>
        );
    }
}

export { AppRouter };
