import React, { useState } from 'react';
import {
    ArrowLeftOutlined, FilterOutlined, DownloadOutlined
} from '@ant-design/icons';
import './dataLayout.less';
import userIcon from '../../assets/images/user_icon.svg'
import { useHistory } from 'react-router-dom';
import oslerLogo from '../../assets/images/osler_round.svg';
import { Dropdown, Menu, Tooltip, Button } from 'antd';
import { userLogout } from '../../appRedux/actions/Authentication';
import { useDispatch } from 'react-redux';
import oslerFullLogo from '../../assets/images/full_app_logo.png';

export const DataLayout = (props) => {
    const { children, title, isHomeScreen, showUser, showFilter, showDownload, downloadData,hideChildrenPadding, showCTApadding, showAdminSettings } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [container, setContainer] = useState(null);
    const logoutUser = () => {
        dispatch(userLogout(()=>{
            history.go(0)
        }))
    }

    window.addEventListener('resize', (event) => {
        setWindowWidth(window.innerWidth)
    })



    const adminPanelMenu = (
        <Menu>
            <Menu.Item  onClick={()=>history.push('/resetpass')}>
                <span className='hover-links-wrapper width-100'>
                Reset Password
                </span>
            </Menu.Item>
            <Menu.Item onClick={logoutUser}>
                <span className='hover-links-wrapper width-100' >
                Logout
                </span>
            </Menu.Item>
        </Menu>
    );


    return (
        <div className='data-main-container' ref={setContainer}>
            <div className='data-header'>
                {
                    isHomeScreen ?
                    windowWidth > 768 ?<img className="full-logo-wrapper" src={oslerFullLogo} alt='osler-logo' />: <img src={oslerLogo} alt='osler-logo' />
                    :<h3 className='capitalize-text width-100'><span><ArrowLeftOutlined onClick={() => { history.goBack() }} /></span>{title}</h3>
                }
                {
                    showUser ?
                        <img className='user-icon-hover' src={userIcon} alt='user' onClick={() => { history.push('/account') }} />
                        : null
                }
                {
                    showAdminSettings ?
                        <Dropdown overlay={adminPanelMenu}>
                            <img className='user-icon-hover ant-dropdown-link'
                                src={userIcon} alt='user'
                                onClick={e => e.preventDefault()} />
                        </Dropdown>
                        : null
                }

            </div>
            {
                showDownload?
                    windowWidth < 768 ?
                    <div className='data-cta position-bottom'>
                        <Tooltip title='Download All'>
                            <Button type='primary' className='float-download-icon'
                                shape='round' icon={<DownloadOutlined />} 
                                size='large' onClick={() => { downloadData() }}>Download All</Button>
                        </Tooltip>
                    </div>
                    :null:null
            }
            {
                showDownload || showFilter || showCTApadding?
                <div className='data-cta'>
                {
                    showDownload ? windowWidth > 768 ?
                        <div className='hover-links-wrapper download-button-margin-right' onClick={() => { downloadData() }}>
                            <Button type='primary' shape='round' icon={<DownloadOutlined />} size='medium'>
                                Download All
                            </Button>
                        </div>:null
                        : null
                }
                {
                    showFilter ?
                        <div>
                            <span>Filter</span>
                            <FilterOutlined />
                        </div>
                        : null
                }

            </div>
            :
            null
            }
            <div className={hideChildrenPadding?'data-content-wrapper-no-padding':'data-content-wrapper'}>
                {
                    children
                }
            </div>

        </div>
    )
}
