import React from 'react';
import { Card, Col, Row, Button, Tooltip } from 'antd';
import {
    DownloadOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './pathology-tile.less';
import { useHistory } from 'react-router-dom';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';
import { toLower } from 'lodash';

const PathologyTile = (props) => {
    const { created_at_plato, document_list, id, dob} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const downloadReport = (idx, name) => {
        dispatch(userDownloadPDF({'name':'pathology_report', 'document_id': id, 'report_idx': idx,
                                'filename':`${name}.pdf`}))
    }
    return (
        <Card className='pathology-card'>
            <div className='title-wrapper'>
                <h3 className='date date-section'>{moment(created_at_plato).format('DD MMM YYYY')}</h3>
            </div>
            <div className='hover-links-wrapper'>
            {
                document_list.map((value,idx) => (
                    <div className='pathology-tile-row'>
                        <div className='pathology-tile-elm'>
                            <h3 className='capitalize-text'>{toLower(value)}</h3>
                        </div>
                        <Button shape='circle' icon={<DownloadOutlined />} onClick={()=>downloadReport(idx, value)} />
                    </div>
                ))
            }
            </div>
        </Card>
    );
}

export default PathologyTile;
