import React, { useState, useEffect, useRef } from 'react';
import { Table, Pagination } from 'antd';
import {
    ArrowDownOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './vaccine-table.less';

import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';


const VaccineTable = (props) => {

    const dispatch = useDispatch();
    const { responseData, totalCount, page, page_size } = props
    let temp = 0
    const changePage = (page) => {
        props.changePage(page)
    }
    
    return (

        <>

        {
            Object.keys(responseData).length > 0 ? 
            
            (
                <>
                    <div className="vaccine-container">

                        <div className="vaccine-container-item-1">                        
                            <table className="vaccine-table">
                                <tbody>
                                    {
                                        Object.keys(responseData).map((key,index) => (
                                            <tr key={++temp} className="vaccine-row">
                                                <td className="vaccine-elm capitalize-text"> {key  === 'null' ? '-' : key} </td>
                                                {responseData[key].map(val => (
                                                    <td key={++temp} className="vaccine-elm"> {moment(val.date).utcOffset('+0800').format('DD MMM YYYY')} <br></br> {val.age} </td>
                                                ))}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="vaccine-container-item-2">
                            <Pagination
                                className = "table-layout-pagination"
                                onChange={changePage}
                                total={totalCount}
                                current={page}
                                pageSize={page_size}
                            />
                        </div>

                    </div>

                </>
            )

            :

            (<></>)
    
        }
        
        
        </>
        
    );
}

export default VaccineTable;
