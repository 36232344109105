import { combineReducers } from 'redux'
import Authentication from './Authentication';
import OTPVerification from './Otp';
import ResetPassword from './resetpassword';
import Loader from './Loader';
import Profile from './Profile';
import Invoice from './Invoice';
import Medication from './Medication';
import KeyMeasurement from './KeyMeasurements';
import Vaccine from './Vaccine';
import DownloadPDF from './DownloadPDF';
import AppointReq from './AppointmentRequest';
import InvoiceDetail from './InvoiceDetails';
import AdminPanel from './AdminPanel';
import Covid from './Covid';
import Pathology from './Pathology';

const rootReducer = combineReducers({
    loader: Loader,
    auth:Authentication,
    otp:OTPVerification,
    resetpass: ResetPassword,
    profile: Profile,
    invoice: Invoice,
    medication: Medication,
    keymeasurement: KeyMeasurement,
    vaccine: Vaccine,
    downloadpdf: DownloadPDF,
    appointmentreq: AppointReq,
    invoicedetail: InvoiceDetail,
    adminpanel: AdminPanel,
    covid: Covid,
    pathology: Pathology
});

export default rootReducer;