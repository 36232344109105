import { covidConstants } from '../constants';

const initialSettings = {
    isCovidNotesFetched: false
};

const Covid = (state = initialSettings, action) => {
    switch (action.type) {

        case covidConstants.COVID_REQUEST:
            return {
                ...state,
                isCovidNotesFetched: false
            };
        case covidConstants.COVID_SUCCESS:
            return {
                ...state,
                isCovidNotesFetched: true,
                data: action.response
            };
        case covidConstants.COVID_FAILURE:
            return {
                ...state,
                isCovidNotesFetched: false
            };
        case covidConstants.COVID_LOADING:
            return {
                ...state,
                isCovidNotesFetched: false
            };

        default:
            return state;
    }
};

export default Covid;
