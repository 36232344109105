import { config } from '../appRedux/constants/app.constants';
import { authHeader } from '../helpers';
import { handleFileResponse } from './handle-response';

export const downloadPDF = (params) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    };

    
    if(params.document_id){
        if(Number.isInteger(params.report_idx)){
            return fetch(`${config.API_URL}/patient/generate_pdf_document/?document_name=${params.name}&document_id=${params.document_id}&report_idx=${params.report_idx}`, requestOptions)
                .then(handleFileResponse)
        }else{
            return fetch(`${config.API_URL}/patient/generate_pdf_document/?document_name=${params.name}&document_id=${params.document_id}`, requestOptions)
                    .then(handleFileResponse)
        }
    }
    else{
        return fetch(`${config.API_URL}/patient/generate_pdf_document/?document_name=${params.name}`, requestOptions)
            .then(handleFileResponse)
    }
}