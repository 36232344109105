import React from 'react';

import { DataLayout } from '../../layouts/data/dataLayout';

import VaccineDetailsTile from '../../components/vaccine-details-tile';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';


const VaccinationDetailsPage = (props) => {

    const dispatch = useDispatch();

    const vaccineDetails = props.location.state.details
    const vaccineName = props.location.state.name

    const downloadVaccination = () => {
        dispatch(userDownloadPDF({'name':'vaccine', 'document_id': vaccineName, 'filename':`vaccine_${moment().format('DD-MM-YYYY')}.pdf`}))
    }


    return (
        <DataLayout
            title={vaccineName === 'null' ? '-' : vaccineName}
            showUser={false}
            showDownload={vaccineDetails.length > 0 ? true :false}
            showFilter={false}
            showCTApadding={true}
            downloadData={downloadVaccination}
        >
            {
                vaccineDetails.map(val => <VaccineDetailsTile {...val} />)
            }
        </DataLayout>
    )

}

export default VaccinationDetailsPage;