import { hideLoader, showLoader } from './Loader';
import { showMessage } from '../../helpers'
import { resetPassConstants, confirmForgotPassConstants } from '../constants';
import { resetPass, forgotPassEmailservice, confirmForgotPassService } from '../../services';

export const resetPassword = (data, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        resetPass(data).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Password Reset Failed');
                    dispatch({ type: resetPassConstants.RESET_PASS_FAILURE });
                } else {
                    dispatch({ type: resetPassConstants.RESET_PASS_SUCCESS });
                    showMessage('success', 'Password Changed');
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: resetPassConstants.RESET_PASS_FAILURE });
                showMessage('error', 'Password Reset Failed')
                console.log(error)
            }
        )
    }
}

export const forgotPassEmail = (data, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        forgotPassEmailservice(data).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Failed to send Email.');
                } else {
                    showMessage('success', 'Email Sent.');
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                showMessage('error', 'Failed to send Email.')
                console.log(error)
            }
        )
    }
}

export const confirmForgotPass = (data, onSuccess) => { //used for confirming the forgot pass
    return dispatch => {
        dispatch(showLoader())
        confirmForgotPassService(data).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Password Reset Failed');
                    dispatch({ type: confirmForgotPassConstants.CONFIRM_FORGOT_PASS_FAILURE });
                } else {
                    dispatch({ type: confirmForgotPassConstants.CONFIRM_FORGOT_PASS_SUCCESS });
                    showMessage('success', 'Password Changed');
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: confirmForgotPassConstants.CONFIRM_FORGOT_PASS_FAILURE });
                showMessage('error', 'Password Reset Failed')
                console.log(error)
            }
        )
    }
}
