import { hideLoader, showLoader } from './Loader';
import { showMessage } from '../../helpers'
import { invoiceDetailsConstants } from '../constants';
import { getInvoiceDetailData } from '../../services';

export const userInvoiceDetail = (invoice_id, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        getInvoiceDetailData(invoice_id).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Unable to fetch data');
                    dispatch({ type: invoiceDetailsConstants.INVOICE_DETAIL_FAILURE });
                } else {
                    dispatch({ type: invoiceDetailsConstants.INVOICE_DETAIL_SUCCESS, response });
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: invoiceDetailsConstants.INVOICE_DETAIL_FAILURE });
                showMessage('error', 'Unable to fetch data')
                console.log(error)
            }
        )
    }
}

export const invoiceDetailLoaded = () => {
    return dispatch => {
        dispatch({ type: invoiceDetailsConstants.INVOICE_DETAIL_LOADED })
    }
}