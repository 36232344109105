import { hideLoader, showLoader } from './Loader';
import { showMessage } from '../../helpers'
import { pathologyConstants } from '../constants';
import { getPathologyReportData } from '../../services';

export const userPathologyReport = (params, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        getPathologyReportData(params).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Unable to fetch data');
                    dispatch({ type: pathologyConstants.PATHOLOGY_FAILURE });
                } else {
                    dispatch({ type: pathologyConstants.PATHOLOGY_SUCCESS, response });
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: pathologyConstants.PATHOLOGY_FAILURE });
                showMessage('error', 'Unable to fetch data')
                console.log(error)
            }
        )
    }
}

export const pathologyReportLoad = () => {
    return dispatch => {
        dispatch({ type: pathologyConstants.PATHOLOGY_LOADING })
    }
}