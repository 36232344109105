import { syncData } from '../../services';
import { authenticationConstants } from '../constants';

export const userSyncData = (onSuccess) => {
    return dispatch => {
        syncData().then(
            response => {
                if(response){
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                console.log(error)
            }
        )
    }
}
