import React, { useState, useEffect } from 'react';

const Auth = (WrappedComponent, Roles) => {

    const AuthCheck = (props) => {
        const [isAuthorized, setIsAuthorized] = useState(false);

        useEffect(() => {
            let collectData = []
            let user = JSON.parse(localStorage.getItem('user'));
            if (user && user.key) {
                collectData = Roles.filter(data => user.is_admin === data);
                if (collectData.length) {
                    setIsAuthorized(true)
                }
            } else {
                props.history.push('/login');
            }
        }, [])

        if (isAuthorized) {
            return <WrappedComponent {...props} />;
        } else {
            return null;
        }
    }
    return AuthCheck;
};

export { Auth };
