import React from 'react';
import { Card, Col, Row } from 'antd';
import {
    ArrowDownOutlined
} from '@ant-design/icons';
import moment from 'moment';
import './vaccine-tile.less';
import vaccineIcon from '../../assets/images/vaccine_icon.svg';
import { useHistory } from 'react-router-dom';


const VaccineTile = (props) => {
    const {vaccineLastTaken, vaccineName, vaccineDetails} = props;
    const history = useHistory();
    return (
        <Card onClick={() => history.push({pathname: '/vaccine-details', state: {'name':vaccineName, 'details':vaccineDetails}})} className='vaccine-card btn-link'>
            <Row className={'data-wrapper'}>
                <Col xs={6} className='data-icon'>
                    <img src={vaccineIcon} alt='vaccine-icon' />
                </Col>
                <Col xs={18}>
                    <Row>
                        <Col>
                            <span className='capitalize-text'>{ vaccineName === 'null' ? '-' : vaccineName }</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h5>Last Taken</h5>
                        </Col>
                        <Col xs={12} className='date-text-wrapper'>
                            <span>{moment(vaccineLastTaken).utcOffset('+0800').format('DD MMM YYYY')}</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
}

export default VaccineTile;
