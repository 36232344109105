import { config } from '../appRedux/constants/app.constants';
import { authHeader } from '../helpers';
import { handleResponse } from './handle-response';

export const resetPass = (data) => {
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`${config.API_URL}/users/password/change/`, requestOptions)
        .then(handleResponse)
}

export const forgotPassEmailservice = (data) => {
    
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.API_URL}/users/password/reset`, requestOptions)
        .then(handleResponse)
}

export const confirmForgotPassService = (data) => {

    const uid = data.uid
    const token = data.token
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.API_URL}/users/auth/password/reset/confirm/`, requestOptions)
        .then(handleResponse)
}