import { authenticationConstants } from '../constants';

const initialSettings = {
    isUserLoggedIn: false,
    isUserSignedUp: false,
    isEmailVerified: false,
    isUserLoggedOut: false,
    isLoginToOTP: false,    //redirect to OTP screen if the user's otp is not verified yet at the time of login
};

const Authentication = (state = initialSettings, action) => {
    switch (action.type) {

        case authenticationConstants.LOGIN_REQUEST:
            return {
                ...state,
                isUserLoggedIn: false
            };
        case authenticationConstants.LOGIN_SUCCESS:
            return {
                ...state,
                isUserLoggedIn: true
            };
        case authenticationConstants.LOGIN_FAILURE:
            return {
                ...state,
                isUserLoggedIn: false
            };

        case authenticationConstants.SIGNUP_FAILURE:
            return {
                ...state,
                isUserSignedUp: false
            }

        case authenticationConstants.SIGNUP_REQUEST:
            return {
                ...state,
                isUserSignedUp: false
            }

        case authenticationConstants.SIGNUP_SUCCESS:
            return {
                ...state,
                isUserSignedUp: true
            }

        case authenticationConstants.VERIFY_EMAIL_FAILURE:
            return {
                ...state,
                isEmailVerified: false
            }

        case authenticationConstants.VERIFY_EMAIL_REQUEST:
            return {
                ...state,
                isEmailVerified: false
            }

        case authenticationConstants.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                isEmailVerified: true
            }

        case authenticationConstants.LOGOUT_REQUEST:
            return {
                ...state,
                isUserLoggedOut: false
            };
        case authenticationConstants.LOGOUT_SUCCESS:
            return {
                ...state,
                isUserLoggedOut: true
            };
        case authenticationConstants.LOGOUT_FAILURE:
            return {
                ...state,
                isUserLoggedOut: false
            };
        case authenticationConstants.LOGIN_OTP_VERIFY:
            return {
                ...state,
                isLoginToOTP: true,
                otpdata: action.otpdata
            };
    

        default:
            return state;
    }
};

export default Authentication;
