import React, { useState, useEffect, useRef } from 'react';

import { DataLayout } from '../../layouts/data/dataLayout';

import sampleResponse from '../../sample-response/vaccination.json'
import VaccineTile from '../../components/vaccine-tile';
import VaccineTable from '../../components/vaccine-table';

import { useDispatch, useSelector } from 'react-redux';
import { userVaccine, vaccineLoad } from '../../appRedux/actions/Vaccine';
import { useHistory } from 'react-router-dom';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import moment from 'moment';
import NoDataScreen from '../../components/no-data-screen';


const VaccinationPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const page_size = 10
    const { isVaccineFetched, data } = useSelector(({ vaccine }) => vaccine);
    const [responseData, setResponseData] = useState({});
    const [page, setPage] = useState(1);
    const loader = useRef(null);
    const [isChildRendered, setChildRendered] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [showNoData, setShowNoData] = useState(false);

    const [flag, setFlag] = useState(0)
    const [initial, setInitial] = useState(true)
    const [tableResponseData, setTableResponseData] = useState([])
    const [tablePage, setTablePage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    window.addEventListener('resize', (event) => {
        setWindowWidth(window.innerWidth)
    })

    useEffect(() => {
        dispatch(userVaccine({'page':page, 'page_size':page_size}))
    }, []);

    useEffect(() => {
        if(isChildRendered){
            var options = {
                root: null,
                rootMargin: "20px",
                threshold: 1.0
            };
            const observer = new IntersectionObserver(handleObserver, options);
            if (loader.current) {
                observer.observe(loader.current)
            }
        }
    }, [isChildRendered])

    useEffect(() => {
        if(isVaccineFetched){

            if (initial == true){
                setTableResponseData(data.vaccines)
                let newData = {...responseData, ...data.vaccines}
                setResponseData(newData)
                setInitial(false)
            }
            else if (windowWidth < 768){
                let newData = {...responseData, ...data.vaccines}
                setResponseData(newData)
            }
            else if (windowWidth > 768){
                setTableResponseData(data.vaccines)
            }

            dispatch(vaccineLoad())
            setChildRendered(true)
            setTotalCount(data.total_vaccines)
            setTotalPages(data.total_pages)
            setShowNoData(Object.keys(data.vaccines).length < 1)
        }
    }, [isVaccineFetched])

    useEffect(() => {
        if(windowWidth < 768 && initial == false){
            setPage((page) => page + 1)
            if(page > 1){
                if(totalPages >= page){
                    dispatch(userVaccine({'page':page, 'page_size':page_size}))
                }
            }
        }
    }, [flag])

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            if( totalPages > page)
                setFlag((flag) => flag + 1)
        }
    }

    const downloadVaccination = () => {
        dispatch(userDownloadPDF({'name':'vaccine', 'filename':`vaccine_${moment().format('DD-MM-YYYY')}.pdf`}))
    }
    const getVaccineDetails = (data) => {
        let vaccineNames = Object.keys(data);
        return vaccineNames.map(val => ({
            vaccineName: val,
            vaccineLastTaken: data[val][0].date,
            vaccineDetails: data[val]
        }))
    }

    const changePageForTable = (page) => {
        dispatch(userVaccine({'page':page, 'page_size':page_size}))
        setTablePage(page)
    }

    return (
        <DataLayout
            title='Vaccinations'
            showUser={false}
            showDownload={Object.keys(responseData).length > 0 ? true :false}
            showFilter={false}
            downloadData={downloadVaccination}
        >
            
            {windowWidth > 768 ? (
                <>  { showNoData ? <NoDataScreen/> : <VaccineTable responseData={tableResponseData} totalCount={totalCount} page={tablePage} page_size={page_size} changePage={changePageForTable} />} </>
            ) : (
                <> { showNoData ? <NoDataScreen/> : getVaccineDetails(responseData).map(val => <VaccineTile {...val} />) }  </>
            )}

            
            {
                isChildRendered ? 
                <div className="loading" ref={loader}>
                </div>
                :null
            }

        </DataLayout>
    )

}

export default VaccinationPage;