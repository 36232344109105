import { medicationConstants } from '../constants';

const initialSettings = {
    isMedicationFetched: false
};

const Medication = (state = initialSettings, action) => {
    switch (action.type) {

        case medicationConstants.MEDICATION_REQUEST:
            return {
                ...state,
                isMedicationFetched: false
            };
        case medicationConstants.MEDICATION_SUCCESS:
            return {
                ...state,
                isMedicationFetched: true,
                data: action.response
            };
        case medicationConstants.MEDICATION_FAILURE:
            return {
                ...state,
                isMedicationFetched: false
            };

        case medicationConstants.MEDICATION_LOADING:
            return {
                ...state,
                isMedicationFetched: false
            };

        default:
            return state;
    }
};

export default Medication;
