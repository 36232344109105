import { config } from '../appRedux/constants/app.constants';
import { authHeader } from '../helpers';
import { handleResponse } from './handle-response';

export const getKeyMeasurementData = (params) => {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    };

    return fetch(`${config.API_URL}/patient/keymeasurement/?page=${params.page}&page_size=${params.page_size}`, requestOptions)
        .then(handleResponse)
}