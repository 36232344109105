import React, { useState, useEffect } from 'react';

import { DataLayout } from '../../layouts/data/dataLayout';
import KeyMeasurementTile from '../../components/key-measurement-tile';
import { Card, Col, Row, Modal, Radio } from 'antd';
import { useHistory } from 'react-router-dom';

import keyMeasurementIcon from '../../assets/images/key_measurement.svg';
import invoiceIcon from '../../assets/images/no_circle_invoices.svg';
import medicineIcon from '../../assets/images/no_circle_medication.svg';
import vaccineIcon from '../../assets/images/no_circle_vaccination.svg';
import phoneIcon from '../../assets/images/phone.svg';
import emailIcon from '../../assets/images/email.svg';
import covidIcon from '../../assets/images/covid_icon.svg';
import pathologyIcon from '../../assets/images/pathology_icon.svg';
import { userProfile } from '../../appRedux/actions/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { userSendAppReq } from '../../appRedux/actions/AppointmentRequest';
import './homepage.less';
import { startCase, toLower } from 'lodash';



const HomePage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { isProfileFetched, data } = useSelector(({ profile }) => profile);
    const {isAppointReqSent} = useSelector(({ appointmentreq }) => appointmentreq);
    const [Name, setName] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [emailValue, setEmailValue] = useState('raffles@osler-health.com')
    const [apptTypeValue, setApptTypeValue] = useState('inclinic')

    useEffect(() => {
        dispatch(userProfile())
    }, []);

    useEffect(() =>{
        if (isProfileFetched){
            let name = data[0].name;
            setName(name)
        }
    }, [isProfileFetched])

    const homepageTiles=[
        {imgSource:keyMeasurementIcon, tileText:'Key Measurements', navigateUrl:'/key-measurements'},
        {imgSource:medicineIcon, tileText:'Medication', navigateUrl:'/medications'},
        {imgSource:vaccineIcon, tileText:'Vaccinations', navigateUrl:'/vaccine'},
        {imgSource:invoiceIcon, tileText:'Invoices', navigateUrl:'/invoices'},
        {imgSource:covidIcon, tileText:'COVID-19 Records', navigateUrl: '/covid-notes'},
        {imgSource:pathologyIcon, tileText:'Results', navigateUrl: '/results'}
    ]

    const handleAppointReq = (email, apptType) => {
        dispatch(userSendAppReq({"request_type": "email", "osler_email":email, "appointment_type":apptType}))
    }

    const handleCallReq = () => {
        document.location.href = 'tel:+65 6332 2727'
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        handleAppointReq(emailValue, apptTypeValue)
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const emailOptions = [
        { label: 'Raffles', value: 'raffles@osler-health.com' },
        { label: 'Star Vista', value: 'starvista@osler-health.com' }
    ];

    const apptReqTypeOptions = [
        { label: 'In-Clinic Appointment', value: 'inclinic' },
        { label: 'Telemedicine Appointment', value: 'telemedicine' }
    ];

    const onChangeEmail = (e) => {
        setEmailValue(e.target.value)
    }

    const onChangeApptType = (e) => {
        setApptTypeValue(e.target.value)
    }

    useEffect(() =>{
        if (isAppointReqSent){
            //do nothing
            console.log('success')
        }
    }, [isAppointReqSent])

    return (
        <DataLayout
            isHomeScreen={true}
            showUser={true}
            showDownload={false}
            showFilter={false}
            showCTApadding={true}
        >
            <Modal className='email-modal-wrapper' 
                title='Request for an Appointment'
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}>
                
                <h3 className='pt-10'>Appointment Type:</h3>
                <Radio.Group
                    options={apptReqTypeOptions}
                    value={apptTypeValue}
                    onChange={onChangeApptType}
                    optionType='button'
                    buttonStyle='solid'
                />
                <h3 className='pt-10'>Clinic Name:</h3>
                <Radio.Group
                    options={emailOptions}
                    value={emailValue}
                    onChange={onChangeEmail}
                    optionType='button'
                    buttonStyle='solid'
                />
            </Modal>
            <div className='home-page-title-container'>
                <div className='homepage-title-wrapper'>
                    <div className='title-container-padding'>
                        <h1>Hello {startCase(toLower(Name))},</h1>
                        <span>Welcome to Osler Health International</span>
                    </div>
                </div>
            </div>

            <Row className="outer-container-1">
                {
                    homepageTiles.map(value=>(
                        <Col xs={24} sm={24} md={8} lg={6} className="outer-container-1-item">
                            <Card onClick={() => history.push(value.navigateUrl)} className='homepage-card btn-link'>
                                <div className="inner-container-1">
                                    <div className='data-icon inner-container-1-item-1'>
                                        <img src={value.imgSource} alt='keymeasurement' />
                                    </div>
                                    <div className='data-tile submenu-names inner-container-1-item-2'>
                                        <span>{value.tileText}</span>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    ))
                }
            </Row>

            <div className='homepage-appointment-wrapper'>
                <span className='homepage-text-wrapper title-container-padding'>Request for an Appointment</span>
            </div>
            
            <Row className="outer-container-1">
                <Col xs={12} md={8} lg={6} className='outer-container-1-item'>
                    <Card className='homepage-request-appointment-card call-request' onClick={handleCallReq}>
                        <div>
                            <img src={phoneIcon} alt='keymeasurement' />
                        </div>
                        <div class="show-call-text">
                            <h3>
                                Call
                            </h3>
                        </div>
                        <div class="show-call-number">
                            <h3>
                                +65 6332 2727
                            </h3>
                        </div>
                    </Card>
                </Col>
                <Col xs={12} md={8} lg={6} className='outer-container-1-item sm-padding-left'>
                    <Card className='homepage-request-appointment-card' onClick={showModal}>
                        <div>
                            <img src={emailIcon} alt='keymeasurement' />
                        </div>
                        <h3>
                            Email
                        </h3>
                    </Card>
                </Col>
            </Row>
            
        </DataLayout>
    )

}

export default HomePage;