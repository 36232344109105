import { config } from '../appRedux/constants/app.constants';
import { authHeader } from '../helpers';
import { handleResponse } from './handle-response';

export const syncData = () => {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
    };

    return fetch(`${config.API_URL}/patient/sync-updates/`, requestOptions)
        .then(handleResponse)
}