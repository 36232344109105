import { pathologyConstants } from '../constants';

const initialSettings = {
    isPathologyReportFetched: false
};

const Pathology = (state = initialSettings, action) => {
    switch (action.type) {

        case pathologyConstants.PATHOLOGY_REQUEST:
            return {
                ...state,
                isPathologyReportFetched: false
            };
        case pathologyConstants.PATHOLOGY_SUCCESS:
            return {
                ...state,
                isPathologyReportFetched: true,
                data: action.response
            };
        case pathologyConstants.PATHOLOGY_FAILURE:
            return {
                ...state,
                isPathologyReportFetched: false
            };
        case pathologyConstants.PATHOLOGY_LOADING:
            return {
                ...state,
                isPathologyReportFetched: false
            };

        default:
            return state;
    }
};

export default Pathology;
