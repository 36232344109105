import React, { useState, useEffect, useRef } from 'react';

import { DataLayout } from '../../layouts/data/dataLayout';
import PathologyTile from '../../components/pathology-tile';
import PathologyTable from '../../components/pathology-table';

import { useDispatch, useSelector } from 'react-redux';
import { userPathologyReport, pathologyReportLoad } from '../../appRedux/actions/Pathology';
import { useHistory } from 'react-router-dom';
import NoDataScreen from '../../components/no-data-screen';


const PathologyPage = () => {

    const page_size = 10

    const dispatch = useDispatch();
    const history = useHistory();
    const { isPathologyReportFetched, data } = useSelector(({ pathology }) => pathology);
    const [responseData, setResponseData] = useState([]);
    const [page, setPage] = useState(1);
    const loader = useRef(null);
    const [isChildRendered, setChildRendered] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [showNoData, setShowNoData] = useState(false);

    const [flag, setFlag] = useState(0)
    const [initial, setInitial] = useState(true)
    const [tableResponseData, setTableResponseData] = useState([])
    const [tablePage, setTablePage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    window.addEventListener('resize', (event) => {
        setWindowWidth(window.innerWidth)
    })


    useEffect(() => {
        dispatch(userPathologyReport({'page':page, 'page_size':10}))
    }, []);

    useEffect(() => {
        if(isChildRendered){
            var options = {
                root: null,
                rootMargin: "20px",
                threshold: 1.0
            };
            const observer = new IntersectionObserver(handleObserver, options);
            if (loader.current) {
                observer.observe(loader.current)
            }
        }
    }, [isChildRendered])

    useEffect(() => {
        if(isPathologyReportFetched){
            if (initial == true){
                setResponseData(responseData.concat(data.results))
                setTableResponseData(data.results)
                setInitial(false)
            }
            else if (windowWidth < 768){
                setResponseData(responseData.concat(data.results))
            }
            else if (windowWidth > 768){
                setTableResponseData(data.results)
            }

            dispatch(pathologyReportLoad())
            setChildRendered(true)
            setTotalCount(data.count)
            setShowNoData(data.results.length < 1)
        }
    }, [isPathologyReportFetched])

    useEffect(() => {
        if(windowWidth < 768 && initial == false){
            setPage((page) => page + 1)
            if(page > 1){
                if(totalCount !== 0 && (totalCount - responseData.length) > 0){
                    dispatch(userPathologyReport({'page':page, 'page_size':page_size}))
                }
            }
        }
    }, [flag])

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            if(responseData.length < totalCount)
                setFlag((flag) => flag + 1)
        }
    }

    const changePageForTable = (page) => {
        dispatch(userPathologyReport({'page':page, 'page_size':page_size}))
        setTablePage(page)
    }


    return (
        <DataLayout
            title='Results'
            showUser={false}
            showDownload={false}
            showFilter={false}
            showCTApadding={true}
        >
            {windowWidth > 768 ? (
                <>   { showNoData ? <NoDataScreen/> : <PathologyTable responseData={tableResponseData} totalCount={totalCount} page={tablePage} page_size={page_size} changePage={changePageForTable}/> } </>
            ) : (
                <>  { showNoData ? <NoDataScreen/> : responseData.map(val => <PathologyTile {...val} />) } </>
            )}
            {
                isChildRendered ? 
                <div className="loading" ref={loader}>
                </div>
                :null
            }
            
        </DataLayout>
    )

}

export default PathologyPage;