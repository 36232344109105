import { config } from '../appRedux/constants/app.constants';
import { authHeader } from '../helpers';
import { handleResponse } from './handle-response';

export const login = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.API_URL}/users/login`, requestOptions)
        .then(handleResponse)
}

export const signup = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.API_URL}/users/registration/`, requestOptions)
        .then(handleResponse)
}

export const verifyEmailService = (data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${config.API_URL}/users/registration/verify-email/`, requestOptions)
        .then(handleResponse)
}

export const logout = () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`${config.API_URL}/users/logout`, requestOptions)
        .then(handleResponse)
}