import { hideLoader, showLoader } from './Loader';
import { showMessage } from '../../helpers'
import { appointReqConstants } from '../constants';
import { sendAppointReq } from '../../services';

export const userSendAppReq = (data, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        sendAppointReq(data).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Failed to send request');
                    dispatch({ type: appointReqConstants.APPOINTREQ_FAILURE });
                } else {
                    dispatch({ type: appointReqConstants.APPOINTREQ_SUCCESS });
                    showMessage('success', 'Appointment Request placed');
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: appointReqConstants.APPOINTREQ_FAILURE });
                showMessage('error', `${error.non_field_errors[0]}`)
                console.log(error)
            }
        )
    }
}