import { hideLoader, showLoader } from './Loader';
import { showMessage } from '../../helpers'
import { downloadPDFConstants } from '../constants';
import { downloadPDF } from '../../services';
import { saveAs } from 'file-saver';

import { Plugins, FilesystemDirectory, Capacitor } from '@capacitor/core';
import { FileOpener } from '@ionic-native/file-opener';

export const userDownloadPDF = (params, onSuccess) => {
    const {Filesystem} = Plugins;
    return dispatch => {
        dispatch(showLoader())
        downloadPDF(params).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Unable to download');
                    dispatch({ type: downloadPDFConstants.DOWNLOADPDF_FAILURE });
                } else {
                    if(Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios'){
                        let wthoutExt = params.filename.split('.pdf')[0]
                        var reader = new FileReader();
                        reader.readAsDataURL(response);
                        reader.onloadend = function() {
                            var base64data = reader.result;
                            Filesystem.writeFile({
                                data:base64data,
                                path:'Downloads/'+wthoutExt+Math.random().toString(36).substr(2, 5)+'.pdf',
                                directory:FilesystemDirectory.External,
                                recursive: true
                            }).then(res=>{
                                FileOpener.open(res.uri, 'application/pdf')
                                dispatch({ type: downloadPDFConstants.DOWNLOADPDF_SUCCESS, response });
                            })
                        }
                    }else{
                        saveAs(response, params.filename)
                        dispatch({ type: downloadPDFConstants.DOWNLOADPDF_SUCCESS, response});
                    }
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: downloadPDFConstants.DOWNLOADPDF_FAILURE });
                showMessage('error', 'Unable to download')
                console.log(error)
            }
        )
    }
}

export const downloadFinished = () => {
    const data = null;
    return dispatch => {
        dispatch({ type: downloadPDFConstants.DOWNLOADPDF_FINISHED, data })
    }
}