import React from 'react';
import './authLayout.less';
import logo from '../../assets/images/osler-logo-full.png'
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

export const AuthLayout = (props) => {

    const {isBackArrow} = props
    const history = useHistory();

    return (
        <div className='auth-main-container'>
            { isBackArrow ?
            <div className='arrow-wrapper'>
                <h3><span><ArrowLeftOutlined onClick={() => { history.goBack() }} /></span></h3>
            </div>
            :null
            }
            <div className='logo-wrapper'>
                <img src={logo} alt='logo' />
            </div>
            {
                props.children
            }
        </div>
    )
}

