import { invoiceConstants } from '../constants';

const initialSettings = {
    isInvoiceFetched: false
};

const Invoice = (state = initialSettings, action) => {
    switch (action.type) {

        case invoiceConstants.INVOICE_REQUEST:
            return {
                ...state,
                isInvoiceFetched: false
            };
        case invoiceConstants.INVOICE_SUCCESS:
            return {
                ...state,
                isInvoiceFetched: true,
                data: action.response
            };
        case invoiceConstants.INVOICE_FAILURE:
            return {
                ...state,
                isInvoiceFetched: false
            };
        case invoiceConstants.INVOICE_LOADING:
            return {
                ...state,
                isInvoiceFetched: false
            };

        default:
            return state;
    }
};

export default Invoice;
