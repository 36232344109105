import { downloadPDFConstants } from '../constants';

const initialSettings = {
    isDocumentDownloaded: false,
    downloadData: null
};

const DownloadPDF = (state = initialSettings, action) => {
    switch (action.type) {

        case downloadPDFConstants.DOWNLOADPDF_REQUEST:
            return {
                ...state,
                isDocumentDownloaded: false
            };
        case downloadPDFConstants.DOWNLOADPDF_SUCCESS:
            return {
                ...state,
                isDocumentDownloaded: true,
                downloadData: action.response
            };
        case downloadPDFConstants.DOWNLOADPDF_FAILURE:
            return {
                ...state,
                isDocumentDownloaded: false
            };
        case downloadPDFConstants.DOWNLOADPDF_FINISHED:
            return {
                ...state,
                isDocumentDownloaded: false
            };

        default:
            return state;
    }
};

export default DownloadPDF;
