import React, { useState, useEffect, useRef } from 'react';

import { DataLayout } from '../../layouts/data/dataLayout';
import KeyMeasurementTile from '../../components/key-measurement-tile';
import KeyMeasurementTable from '../../components/key-measurement-table';

import sampleResponse from '../../sample-response/key_measurement.json';

import { useDispatch, useSelector } from 'react-redux';
import { userKeyMeasurement, keyMeasurementLoad } from '../../appRedux/actions/KeyMeasurements';
import { useHistory } from 'react-router-dom';
import { userDownloadPDF } from '../../appRedux/actions/DownloadPDF';
import moment from 'moment';
import NoDataScreen from '../../components/no-data-screen';

import './measurement-table.less';
import { Table } from 'antd';

const KeyMeasurementPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const page_size = 10
    
    const { isKeyMeasurementFetched, data } = useSelector(({ keymeasurement }) => keymeasurement);
    const [responseData, setResponseData] = useState([]);
    const [page, setPage] = useState(1);
    const loader = useRef(null);
    const [isChildRendered, setChildRendered] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [showNoData, setShowNoData] = useState(false);

    const [flag, setFlag] = useState(0)
    const [initial, setInitial] = useState(true)
    const [tableResponseData, setTableResponseData] = useState([])
    const [tablePage, setTablePage] = useState(1)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    window.addEventListener('resize', (event) => {
        setWindowWidth(window.innerWidth)
    })

    useEffect(() => {
        dispatch(userKeyMeasurement({'page':page, 'page_size':page_size}))
    }, []);

    
    useEffect(() => {
        if(isChildRendered){
            var options = {
                root: null,
                rootMargin: "20px",
                threshold: 1.0,
            };
            const observer = new IntersectionObserver(handleObserver, options);
            if (loader.current) {
                observer.observe(loader.current)
            }
        }
    }, [isChildRendered])

    
    useEffect(() => {
        if(isKeyMeasurementFetched){
            
            if (initial == true){
                setResponseData(responseData.concat(data.results))
                setTableResponseData(data.results)
                setInitial(false)
            }
            else if (windowWidth < 768){
                setResponseData(responseData.concat(data.results))
            }
            else if (windowWidth > 768){
                setTableResponseData(data.results)
            }

            dispatch(keyMeasurementLoad())
            setChildRendered(true)
            setTotalCount(data.count)
            setShowNoData(data.results.length < 1)
        }
    }, [isKeyMeasurementFetched])

    
    useEffect(() => {
        if(windowWidth < 768 && initial == false){
            setPage((page) => page + 1)
            if(page > 1){
                if(totalCount !== 0 && (totalCount - responseData.length) > 0){
                    dispatch(userKeyMeasurement({'page':page, 'page_size':page_size}))
                }
            }
        }
    }, [flag])

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            if(responseData.length < totalCount)
                setFlag((flag) => flag + 1)    
        }
    }

    const changePageForTable = (page) => {
        dispatch(userKeyMeasurement({'page':page, 'page_size':page_size}))
        setTablePage(page)
    }

    const downloadKeyMeasurements = () => {
        dispatch(userDownloadPDF({'name':'key_measurement', 'filename':`key-measurement_${moment().format('DD-MM-YYYY')}.pdf`}))
    }

    return (
        <DataLayout
            title='Key Measurements'
            showUser={false}
            showDownload={responseData.length > 0 ? true :false}
            showFilter={false}
            downloadData={downloadKeyMeasurements}
        >
            
            {windowWidth > 768 ? (
                <>   { showNoData ? <NoDataScreen/> : <KeyMeasurementTable responseData={tableResponseData} totalCount={totalCount} page={tablePage} page_size={page_size} changePage={changePageForTable}/> } </>
            ) : (
                <>  { showNoData ? <NoDataScreen/> : responseData.map(val => <KeyMeasurementTile {...val} />) } </>
            )}

            {
                isChildRendered ? 
                <div className="loading" ref={loader}>
                </div>
                :null
            }

        </DataLayout>
    )

}

export default KeyMeasurementPage;