import { hideLoader, showLoader } from './Loader';
import { showMessage } from '../../helpers'
import { otpConstants } from '../constants';
import { sendOtp, resendOtp } from '../../services';

export const otpVerify = (data, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        sendOtp(data).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Invalid OTP');
                    dispatch({ type: otpConstants.OTP_FAILURE });
                } else {
                    dispatch({ type: otpConstants.OTP_SUCCESS });
                    showMessage('success', 'OTP Verified');
                    localStorage.setItem('user', JSON.stringify(response));
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                dispatch({ type: otpConstants.OTP_FAILURE });
                showMessage('error', 'Invalid OTP')
                console.log(error)
            }
        )
    }
}

export const actionResendOTP = (data, onSuccess) => {
    return dispatch => {
        dispatch(showLoader())
        resendOtp(data).then(
            response => {
                dispatch(hideLoader());
                if (!response) {
                    showMessage('error', 'Technical Difficulty. Please try again.');
                } else {
                    showMessage('success', 'OTP Sent!!');
                    if (onSuccess) {
                        onSuccess()
                    }
                }
            }
        ).catch(
            error => {
                dispatch(hideLoader());
                showMessage('error', 'Technical Difficulty. Please try again.')
                console.log(error)
            }
        )
    }
}