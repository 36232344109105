import React from 'react';
import { DataLayout } from '../../layouts/data/dataLayout';
import './terms-condition.less'

const TermsConditionPage = () => {

    return (
        <DataLayout
            title='Terms & Conditions'
            showUser={false}
            showDownload={false}
            showFilter={false}
            showCTApadding={true}
        >
            <div className='osler-terms-wrapper'>
                <ol type='1'>
                    <li>
                        <b>Terms</b>
                        <p>
                            By accessing our app, <i>my</i>OslerHealth, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing <i>my</i>OslerHealth. The materials contained in <i>my</i>OslerHealth are protected by applicable copyright and trademark law.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Use License</b>
                            <ol type='1'>
                                <li>
                                Permission is granted to temporarily download one copy of <i>my</i>OslerHealth per device for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                                    <ol type='1'>
                                        <li>modify or copy the materials;</li>
                                        <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial)</li>
                                        <li>attempt to decompile or reverse engineer any software contained in <i>my</i>OslerHealth;</li>
                                        <li>remove any copyright or other proprietary notations from the materials; or</li>
                                        <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
                                    </ol>
                                </li>
                                <li>
                                    This license shall automatically terminate if you violate any of these restrictions and may be terminated by ETC Healthcare Pte Ltd at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
                                </li>
                                </ol>
                            </p>
                    </li>
                    <li>
                        <p>
                            <b>Disclaimer</b>
                            <ol type='1'>
                            <li>
                                The materials within <i>my</i>OslerHealth are provided on an 'as is' basis. ETC Healthcare Pte Ltd makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
                            </li>
                            <li>
                            Further, ETC Healthcare Pte Ltd does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to <i>my</i>OslerHealth.
                            </li>
                            </ol>
                        </p>
                    </li>
                    <li>
                        <b>Limitations</b>
                        <p>In no event shall ETC Healthcare Pte Ltd or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use <i>my</i>OslerHealth, even if ETC Healthcare Pte Ltd or a ETC Healthcare Pte Ltd authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                    </li>
                    <li>
                        <b>Accuracy of materials</b>
                        <p>The materials appearing in <i>my</i>OslerHealth could include technical, typographical, or photographic errors. ETC Healthcare Pte Ltd does not warrant that any of the materials on <i>my</i>OslerHealth are accurate, complete or current. ETC Healthcare Pte Ltd may make changes to the materials contained in <i>my</i>OslerHealth at any time without notice. However ETC Healthcare Pte Ltd does not make any commitment to update the materials.</p>
                    </li>
                    <li>
                        <b>Links</b>
                        <p>ETC Healthcare Pte Ltd has not reviewed all of the sites linked to its app and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by ETC Healthcare Pte Ltd of the site. Use of any such linked website is at the user's own risk.</p>
                    </li>
                    <li>
                        <b>Modifications</b>
                        <p>ETC Healthcare Pte Ltd may revise these terms of service for its app at any time without notice. By using <i>my</i>OslerHealth you are agreeing to be bound by the then current version of these terms of service.</p>
                    </li>
                    <li>
                        <b>Governing Law</b>
                        <p>
                            These terms and conditions are governed by and construed in accordance with the laws of Singapore and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                        </p>
                    </li>
                </ol>
            </div>
        </DataLayout>
    );
};

export default TermsConditionPage;
